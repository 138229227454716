import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";
import { Link } from "react-router";
import Loader from "../Components/Loader";
import "./index.scss";
import { Images } from "../assets";
let pagination = "";

class Tiers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            DataisLoaded: false
        };
    }
    componentDidMount() {
        this.getTierList();
    }

    getTierList = () => {
        api.get(`tier/getTiers`).then((response) => {
            const posts = response.data.data.data;
            this.setState({
                posts: posts,
                DataisLoaded: true
            });
        }).catch((err) => { 
            console.log("something went wrong");
        });
    };
    refresh = () => {
        this.getTierList();
    };
    render() {
        const { data } = this.state;
        console.log("New data"+ JSON.stringify(this.state));
        return (
            <div className="Tier-page">
                <Header />
                <Loader />
                <div className="wrapper">
                    <div className="clearfix"></div>
                    <div className="Tier-head">
                        <h2>Configure Tier Category</h2>
                        <div className="Tier-head-right">
                            <Link to="/Tier/CreateTier" className="create">
                                {/* <img src={Images.ADD_ICON} alt={""} /> */}
                                <i class="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;&nbsp;Create New Tier Category
                            </Link>
                        </div>
                    </div>
                    <div className="Tiers">
                        <div className="table-wrapper">
                            <div class="head">
                                <h2>Details</h2>
                            </div>
                            <table className="Tiers-table table table-responsive table-stripped">
                                <thead>
                                    <tr>
                                        <th class="align-left" scope="col">Label ID</th>
                                        <th class="align-left" scope="col">Tier Name</th>
                                        <th class="align-left" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.posts.map((details, i) => (
                                        <TableRow
                                        key={i}
                                        myprop={details}
                                        refreshList={this.refresh}
                                        />
                                    ))}
                                </tbody>
                            </table> 
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class TableRow extends Component {
    constructor(props) {
        super(props);
    };
    render() {
        let details = this.props.myprop;
        return (
            <tr>
                <td className="tierId align-left" data-label="Id">
                    <Link to={"/Tier/ViewTier/"+details._id } params={{ tierId: details._id }}> 
                        {details.order}
                    </Link>
                </td>
                <td className="tierName align-left" data-label="Name">
                    <Link to={"/Tier/ViewTier/"+details._id } params={{ tierId: details._id }}> 
                        {details.name}
                    </Link>
                </td>
                <td className="tierAction align-left" data-label="Action">
                    <Link to={"/Tier/ViewTier/"+details._id } params={{ tierId: details._id }}> 
                        <img src={Images.EditIcon} alt={" Edit Tier Category"} title={" Edit Tier Category"} />
                    </Link>
                </td>
                
            </tr>
        );
    }
}
export default Tiers;