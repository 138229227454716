import React, { Component } from "react";
import { Link } from "react-router";
import Header from "../Components/Header";
import Loader from "../Components/Loader";
import "./index.scss";
import api from "../utils/api.js";
import "../css/custom.css";
import HomeHeader from "../Components/Home-header";
import { Images } from "../assets";

let total = 0;

class TokenSale extends Component {
  render() {
    return (
      <div className="Home-Page">
        <Header />
        <HomeHeader />
        <Loader />
        <div className="wrapper">
          <div className="Home">
            <div className="clearfix"></div>
            <InsvestmentVolume />
            <br />
            <Users />
          </div>
        </div>
      </div>
    );
  }
}

class InsvestmentVolume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }
  componentDidMount() {
    this.getInvestmentVolumeList();
  }

  getInvestmentVolumeList = () => {
    api
      .get("analytics/getInvestmentByCurrency")
      .then((response) => {
        const posts = response.data.data;
        this.setState({ posts });
      })
      .catch((err) => {
        console.log("something went wrong with Investment Volume :- ", err);
      });
  };
  render() {
    total = 0;
    let PresaleWhiteList = this.state.posts.filter((list) => {
      return list?.currency.round === "pre-sale-whitelist";
    })[0]
      ? this.state.posts.filter((list) => {
        return list?.currency.round === "pre-sale-whitelist";
      })[0]
      : {};
    let PresalePublic = this.state.posts.filter((list) => {
      return list?.currency.round === "pre-sale-public";
    })[0]
      ? this.state.posts.filter((list) => {
        return list?.currency.round === "pre-sale-public";
      })[0]
      : {};
    this.state.posts.map((details) => {
      total += details.share_percent;
    });

    return (
      <div className="Token-sale-Investment">
        <div className="table-wrapper">
          <h2>Token Sale</h2>
          <table className="Token-sale-Investment-table">
            <thead>
              <tr>
                <th className="align-left">Currency</th>
                <th>PRE-SALE WHITELIST</th>
                <th>PRE-SALE PUBLIC</th>
                <th className="align-right">Share</th>
              </tr>
            </thead>
            <tbody>
              {this.state.posts ? (
                // this.state.posts.map((details, i) => (
                <tr>
                  <td className="align-left"><img src={Images.BUSD} alt={''} />&nbsp;&nbsp;{this.state.posts[0]?.currency?.currency}</td>
                  <td className="Amount">
                  {PresaleWhiteList?.amount
                                      ?parseFloat(PresaleWhiteList?.amount).toFixed(2)
                                      :0.0}
                    &nbsp;
                    {"BUSD"}&nbsp;
                    /&nbsp;{((PresaleWhiteList?.amount
                                      ?parseFloat(PresaleWhiteList?.amount)
                                      :0.0) * (1 / (PresaleWhiteList?.price
                                                ? parseFloat(PresaleWhiteList.price):0.015))).toFixed(2)}&nbsp;HNTR
                    <br />
                    {/* <p>
                        $0 &nbsp;
                        {"USD"}
                      </p> */}
                  </td>
                  <td className="Amount">
                  {PresalePublic?.amount
                                      ?parseFloat(PresalePublic?.amount).toFixed(2)
                                      :0.0}&nbsp;
                    {"BUSD"}&nbsp;
                    /&nbsp;{((PresalePublic?.amount
                                      ?parseFloat(PresalePublic?.amount)
                                      :0.0) * (1 / (PresalePublic?.price
                                                ? parseFloat(PresalePublic.price):0.025))).toFixed(2)}&nbsp;HNTR
                    <br />
                    {/* <p>
                        $0 &nbsp;
                        {"USD"}
                      </p> */}
                  </td>
                  <td className="align-right">{parseFloat(total).toFixed(2)}</td>
                </tr>
                // ))
              ) : (
                <tr />
              )}
            </tbody><br />
            {/* <tfoot>
              <tr>
                <td className="bold align-left">Total</td>
                <td className="bold">$ &nbsp;{(PresaleWhiteList = this.state.posts.filter((list) => {
                      return list?.currency.round === "pre-sale-whitelist";
                    })[0]
                      ? parseFloat(this.state.posts.filter((list) => {
                        return list?.currency.round === "pre-sale-whitelist";
                      })[0].amount).toFixed(2)
                      : 0.0)}</td>
                <td className="bold">$ &nbsp;{(PresalePublic = this.state.posts.filter((list) => {
                      return list?.currency.round === "pre-sale-public";
                    })[0]
                      ? parseFloat(this.state.posts.filter((list) => {
                        return list?.currency.round === "pre-sale-public";
                      })[0].amount).toFixed(2)
                      : 0.0)}</td>
                <td className="bold align-right">{total}%</td>
              </tr>
            </tfoot> */}
          </table>
        </div>
      </div>
    );
  }
}
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }
  componentWillMount() {
    this.getInvestmentByCountry();
  }

  getInvestmentByCountry = () => {
    api
      .get("analytics/getInvestmentByCountry")
      .then((response) => {
        const posts = response.data.data;
        this.setState({ posts });
      })
      .catch((err) => {
        console.log("error while getInvestmentByCountry :- ", err.response);
      });
  };
  render() {
    return (
      <div className="Token-sale-users">
        <div className="table-wrapper">
          <h2>Users</h2>
          <table className="Token-sale-users-table">
            <thead>
              <tr>
                <th>Country</th>
                <th>Investments(USD)</th>
                <th>Registered Users</th>
                <th>Deposit Count</th>
                <th>Average Investment(USD)</th>
                <th>Share (%)</th>
              </tr>
            </thead>
            <tbody>
              {this.state.posts ? (
                this.state.posts.map((details, i) => (
                  <tr key={i}>
                    <td>{details.country}</td>
                    <td>$ {parseFloat(details.amount).toFixed(2)}</td>
                    <td>{details.registered_users.count}</td>
                    <td>{details.transaction_count}</td>
                    <td>
                      {parseFloat(details.amount / details.registered_users.count).toFixed(2)}
                    </td>
                    <td>{details.share_percent}</td>
                  </tr>
                ))
              ) : (
                <tr />
              )}
            </tbody>
          </table>
          <div className="view-all">

            <Link to="Home/Countries">View All</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default TokenSale;
