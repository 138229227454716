import React, { Component } from "react";
import api from "../../utils/api.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router";
import $ from "jquery";
import "jquery-validation";
import "./index.scss";
import { Images } from "../../assets";
let user;
class Header extends Component {
  constructor(props) {
    user = JSON.parse(localStorage.getItem("Admin_profile"));
    super(props);
    this.state = {
      addClass: false,
      details: [],
      alert: null,
      msg: "",
      error: "",
      sidebar: false,
    };
  }
  toggle() {
    this.setState({ addClass: !this.state.addClass });
  }
  componentDidMount() {
    $(".header-rightImg").hover(function () {
      $(this).add("active");
    });
  }

  doLogout() {
    api.logout();
  }

  componentWillMount() {
    if (localStorage.getItem("Admin_token") == null) {
      window.location.href = "/Login";
    }
      this.getAdminDetails();
  }

  getAdminDetails = () => {
    api
      .get("admin/getDetails")
      .then((response) => {
        const details = response.data.data;
        this.setState({ details: details });
      })
      .catch((err) => {
        console.log("Admin Details Are not comming");
      });
  };

  ShowHideSidebar = () => {
    this.setState({ sidebar: !this.state.sidebar });
  };

  render() {
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">{this.state.error}</p>
      ) : (
        <span />
      );
    if (this.state.addClass) {
      document.body.classList.remove("nav-md");
      document.body.classList.add("nav-sm");
    } else {
      document.body.classList.remove("nav-sm");
      document.body.classList.add("nav-md");
    }
    return (
      <>
        <div className="Header">
          <div className="Header-wrapper">
            <div className="header-left">
              <div className="header-list">
                <Link to="/">
                  <img
                    className="header-logo"
                    src={Images.Logo}
                    alt=""
                    title=""
                  />
                </Link>
                <Link
                  to="/"
                  className="header-item"
                  activeClassName="activeRoute"
                >
                  Home
                </Link>
                <Link
                  to="/Users"
                  className="header-item"
                  activeClassName="activeRoute"
                >
                  Users
                </Link>
                <Link
                  to="/Transactions"
                  className="header-item"
                  activeClassName="activeRoute"
                >
                  Transactions
                </Link>
                <Link
                  to="/Support"
                  className="header-item"
                  activeClassName="activeRoute"
                >
                  Support
                </Link>
                <Link
                  to="/Rounds"
                  className="header-item"
                  activeClassName="activeRoute"
                >
                  Rounds
                </Link>
                <Link
                  to="/Tier"
                  className="header-item"
                  activeClassName="activeRoute"
                >
                  Tier Category
                </Link>
              </div>
            </div>
            <div className="header-right">
              <div className="Mobile-menu">
                <i
                  className="fa fa-user-o"
                  onClick={this.ShowHideSidebar.bind(this)}
                  style={{
                    fontSize: "40px",
                    borderRadius: "50%",
                    border: "1px solid #DADCE0",
                    padding: "5px 10px",
                  }}
                ></i>
              </div>
              <div className="nav navbar-nav navbar-right">
                <span className="email-id">{user?.name}</span>
                <li className="flex">
                  <a
                    className="user-profile dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    style={{ padding: "5px" }}
                  >
                    <i
                      className="fa fa-user-o"
                      style={{
                        fontSize: "40px",
                        borderRadius: "50%",
                        border: "1px solid #DADCE0",
                        padding: "5px 10px",
                      }}
                    ></i>
                  </a>
                  <ul className="dropdown-menu dropdown-usermenu pull-right">
                    <li>
                      <Link to="/MyProfile">My Profile</Link>
                    </li>
                    <li>
                      <Link to="/ChangePassword">Change Password</Link>
                    </li>
                    <li>
                      <a onClick={this.doLogout.bind(this)}>Log Out</a>
                    </li>
                  </ul>
                </li>
              </div>
            </div>
          </div>
        </div>
        {this.state.sidebar ? (
          <div className="sidebar">
            <div className="sidebar-wrapper">
              <div
                className="close-sidebar"
                onClick={this.ShowHideSidebar.bind(this)}
              >
                x
              </div>
              <div className="sidebar-items">
                <Link to="/">
                  <img
                    className="header-logo"
                    src={Images.Logo}
                    alt=""
                    title=""
                  />
                </Link>
                <Link
                  to="/"
                  className="sidebar-item"
                  activeClassName="activeRoute"
                >
                  Home
                </Link>
                <Link
                  to="/Users"
                  className="sidebar-item"
                  activeClassName="activeRoute"
                >
                  Users
                </Link>
                <Link
                  to="/Transactions"
                  className="sidebar-item"
                  activeClassName="activeRoute"
                >
                  Transactions
                </Link>
                <Link
                  to="/Support"
                  className="sidebar-item"
                  activeClassName="activeRoute"
                >
                  Support
                </Link>
                <Link
                  to="/Rounds"
                  className="sidebar-item"
                  activeClassName="activeRoute"
                >
                  Rounds
                </Link>
                <Link className="sidebar-item" onClick={this.doLogout.bind(this)}>
                      Log Out
                </Link>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default Header;
