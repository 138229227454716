import React, { Component } from "react";
import "jquery-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import { Images } from "../../assets";
import Loader from "../../Components/Loader";
import "./index.scss";
import api from "./../../utils/api";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      msg: "",
      forgotPassword: false,
      error: "",
    };
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  doResetPassword = (event) => {
    let tokens = this.getParameterByName("token", window.location.href);
    if (!tokens) {
      this.setState({
        error: "Invalid Url",
      });
    } else if (this.refs.password.value === "") {
      this.setState({
        error: "Password is required field",
      });
    } else if (this.refs.password.value.length < 8) {
      this.setState({
        error: "Password should be min 8 characters long",
      });
    } else if (this.refs.password.value !== this.refs.Confirmpassword.value) {
      this.setState({
        error: "Confirm Password did Match with New Password",
      });
    } else {
      this.setState({
        error: "",
      });
      this.ResetPassword(tokens);
    }
  };

  ResetPassword(tokens) {
    const getfailureAlert = (msg) => (
      <SweetAlert danger title={msg} onConfirm={this.hideAlertFailure}>
        Try again!
      </SweetAlert>
    );
    const getSuccessAlert = (msg) => (
      <SweetAlert
        success
        title={msg}
        onConfirm={this.hideAlertSuccess}
      ></SweetAlert>
    );
    const password = this.refs.password.value;
    const token = tokens;
    var postData = {
      password: password,
      token: token,
    };
    api
      .post("admin/resetPassword", postData)
      .then((res) => {
        this.setState({
          alert: getSuccessAlert(res.data.msg),
        });
      })
      .catch((err) => {
        this.setState({
          alert: getfailureAlert(err.response.data.msg),
        });
      });
  }

  hideAlertSuccess() {
    window.location.href = "/Login";
  }
  hideAlertFailure() {
    this.setState({
      alert: null,
    });
  }

  render() {
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">{this.state.error}</p>
      ) : (
        <span />
      );

    return (
      <div className="ResetPass-page">
        <Loader />
        <div className="logo">
          <img src={Images.Logo} alt="" className="app-logo" />
        </div>
        <div className="login-container">
          <div className="login">
            <div>
              <h2 className="sign-in">Reset Password</h2>
              <div className="control-group">
                <input
                  type="password"
                  className="form-control"
                  ref="password"
                  name="password"
                  placeholder="Password *"
                />
              </div>
              <div className="control-group">
                <input
                  type="password"
                  className="form-control"
                  ref="Confirmpassword"
                  name="Confirmpassword"
                  placeholder=" Confirm Password *"
                />
              </div>
              <div className="control-group">{errorMgs}</div>
              <div className="form-button">
                <button
                  type="submit"
                  className="btn-success"
                  onClick={this.doResetPassword.bind(this)}
                >
                  Reset Password
                </button>
              </div>
            </div>
            {this.state.alert}
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
