import React, { Component } from "react";
import { Link } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import { browserHistory } from "react-router";
import "./index.scss";
import api from "../../utils/api";
import { Images } from "../../assets";
import Loader from "../../Components/Loader";
import Header from "../../Components/Header";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addClass: false,
      details: [],
      alert: null,
      msg: "",
      error: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
  }

  hidePopup = () => {
    $(".changePassword.popup").removeClass("show");
    $("body").removeClass("popup-open");
    $(".popup input").val("");
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
    this.hidePopup();
  }

  hideAlertSuccess() {
    browserHistory.push("/");
  }

  dochangePassword = (event) => {
    if (this.refs.ChangeOldpassword.value === "") {
      this.setState({
        error: "Old Password is required field",
      });
    } else if (this.refs.ChangeNewpassword.value === "") {
      this.setState({
        error: "New Password is required field",
      });
    } else if (this.refs.ChangeNewpassword.value.length < 8) {
      this.setState({
        error: "Password should be min 8 characters long",
      });
    } else if (
      this.refs.ChangeNewpassword.value !==
      this.refs.ChangeconfirmNewPassword.value
    ) {
      this.setState({
        error: "Confirm Password did Match with New Password",
      });
    } else {
      this.setState({
        error: "",
      });
      this.changePassword(event);
    }
  };

  changePassword = () => {
    const getfailureAlert = () => (
      <SweetAlert
        danger
        title="Password Changed unsuccessful!"
        onConfirm={this.hideAlert}
      >
        Try again!
      </SweetAlert>
    );
    const getSuccessAlert = () => (
      <SweetAlert
        success
        title="Password Changed Successfully!"
        onConfirm={this.hideAlertSuccess}
      ></SweetAlert>
    );
    const oldPassword = this.refs.ChangeOldpassword.value;
    const password = this.refs.ChangeNewpassword.value;
    let data = {
      oldpassword: oldPassword,
      password: password,
    };

    api
      .post("admin/changePassword", data)
      .then((res) => {
        this.setState({
          alert: getSuccessAlert(),
        });
      })
      .catch((err) => {
        this.setState({
          alert: getfailureAlert(),
        });
      });
  };

  render() {
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">
          {" "}
          <i className="fa fa-info-circle"></i> {this.state.error}
        </p>
      ) : (
        <span />
      );
    return (
      <div className="changePassword-page">
        <Header />
        {this.state.alert}
        <div className="wrapper ">
          <div id="changePassword" className="changePassword">
            <div className="changePassword-header">
              <h4 className="changePassword-title">Update Password</h4>
            </div>
            <div className="changePassword-body">
              <div className="control-group">
                <label htmlFor="password">old Password * :</label>
                <input
                  type="password"
                  ref="ChangeOldpassword"
                  className="form-control"
                  name="ChangeOldpassword"
                />
              </div>
              <div className="control-group">
                <label htmlFor="password">New Password * :</label>
                <input
                  type="password"
                  ref="ChangeNewpassword"
                  className="form-control"
                  name="ChangeNewpassword"
                  id="ChangeNewpassword"
                />
              </div>
              <div className="control-group">
                <label htmlFor="changeconfirmPassword">
                  Confirm New Password * :
                </label>
                <input
                  type="password"
                  ref="ChangeconfirmNewPassword"
                  className="form-control"
                  name="changeconfirmPassword"
                />
              </div>
              {errorMgs}
            </div>
            <div className="changePassword-footer">
              <div className="form-button">
                <button
                  className="btn-save"
                  onClick={this.dochangePassword.bind(this)}
                >
                  Save
                </button>
                  <Link className="btn-cancel" to="/">Cancel</Link>
              </div>
            </div>
            {/* <div className="changePassword-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={this.dochangePassword.bind(this)}
              >
                Update
              </button>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
export default ChangePassword;
