import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";

import Loader from "../Components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import "./index.scss";
import moment from "moment";
import { Images } from "../assets";
import Web3provider from '../Helpers/Web3Provider';
import RoundSubHeader from "../Components/Round-subheader";
export default class TierVesting extends Component
{
  constructor(props)
  {
    super(props);
    this.updateState    = this.updateState.bind(this);
    this.hideAlertSuccess= this.hideAlertSuccess.bind(this);
    this.hideAlertFailure = this.hideAlertFailure.bind(this);

    //this.saveAllocation = this.saveAllocation.bind(this);
    this.onSucess = props.onSuccess; 
    this.state = 
      {
        alert: "",
        error: "",
        tier_allocation:false,
        tier_id:props.tier_id,
        start_date:"",
        showLoader:false,
      };
      this.Web3Handler = new Web3provider();

      
  }
  checkWeb3()
  {
    //this.Web3Handler.getAmount();
    //this.Web3Handler.createPreSale();
  }
  
  updateState(event)
  {
      this.setState({...this.state,[event.target.name]:event.target.value});
      
  }
  saveVesting()
  {
    const getfailureAlert = (msg) => (
      <SweetAlert danger title="Error" onConfirm={this.hideAlertFailure}>
        {msg}
      </SweetAlert>
    );
    const getSuccessAlert = (msg) => (
      <SweetAlert success title="Success!" onConfirm={this.hideAlertSuccess}>
        {msg}
      </SweetAlert>
    );
  

    let control = this.state;
    const start_date = new Date(this.refs.vestStartDate.value);
    if (control.tier_id === "") {
      this.setState({
        error: "Tier ID is required field",
      });
    }
    else if (start_date === "") {
      this.setState({
        error: "Start Date is required field",
      });
    }
    else
    {
      let postData = 
      {
        tier_id:control.tier_id || this.props.tier_id,
        start_time : moment.utc(start_date).valueOf(), 
      };
      api.showLoader();
      this.setState({...this.state,showLoader:true});
      this.Web3Handler.setVestingForTier(postData).then(res=>{
        api.hideLoader();
        this.setState({
          alert: getSuccessAlert("Vesting Done Successfully"),
        })
        
      }).catch(err=>{
        api.hideLoader();
        this.setState({
          alert: getfailureAlert(err.status==false?"Error":err.message),
        });
       
      });
      
      
      return console.log(postData);
      // api
      //   .post("admin/rounds",postData)
      //   .then((res) => {
      //     this.setState({
      //       alert: getSuccessAlert(res.data.msg),
      //     });
      //   })
      //   .catch((err) => {
      //     this.setState({
      //       alert: getfailureAlert(err.response.data.msg),
      //     });
      //   });

    }

   

  }
  hideAlertSuccess() {
    api.hideLoader();
    this.setState({
      alert: null,
      showLoader:false,
    });
    this.onSucess();
  }
  hideAlertFailure() {
    this.setState({
      alert: null,
      showLoader:false
    });
  }



  render()
  {
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">
          <i className="fa fa-info-circle"></i> {this.state.error}
        </p>
      ) : (
        <span />
      );
    return (
      <div className="Round-page">
        <Loader />
        <Header />
        <div className="wrapper">
          <div className="Round">
            <h2>Tier Vesting for '{this.state.tier_id}'</h2>
            {this.state.alert}
            {/* <p className="message">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p> */}
            
              <>
                <div className="Round-form">
                  <div className="field-block">
                      
                    <div className="field-wrapper">
                      <label>Date</label>
                      <input
                        className="form-elements"
                        name="tier_id"
                        type="hidden"
                         defaultValue={this.state.tier_id}
                       />
                      <input
                        className="form-elements"
                        name="start_date"
                        ref="vestStartDate"
                        type="datetime-local"
                      />
                    </div>
                    <div className="field-wrapper">&nbsp;</div>
                    
                  </div>
                  
                 <br />
                  
                  {errorMgs}
                  <div className="form-button">
                    <button
                      className="btn-save"
                      id="_profile_update"
                      onClick={this.saveVesting.bind(this)}
                      name="profileupdate"
                      disabled={this.state.showLoader}
                    >
                      Save
                    </button>

                    <button
                      className="btn-primary"
                      onClick={this.onSucess}
                      disabled={this.state.showLoader}
                    >
                      Back
                    </button>
                  </div>
                </div>
                <br />
               { /*
                <div className="round-table-wrapper">
                  <h2>Round Details</h2>
                  <table className="round-table">
                    <thead>
                      <tr>
                        <th className="align-left">ROUND</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {RoundDetails && RoundDetails.length > 0 ? (
                        RoundDetails.map((list, i) => (
                          <tr>
                            <td className="align-left">{list.rounds}</td>
                            <td data-label="Date">
                              <span className="Month uppercase">
                                {moment(list.start_date).format("DD MMM, YYYY")}
                              </span>
                            </td>
                            <td data-label="Date">
                              <span className="Month uppercase">
                                {moment(list.end_date).format("DD MMM, YYYY")}
                              </span>
                            </td>
                            <td className="action-btns">
                            <img
                                src={Images.EditIcon}
                                alt={""}
                                onClick={() => this.TierAllocation(list)}
                              />
                              &nbsp; &nbsp;
                              <img
                                src={Images.EditIcon}
                                alt={""}
                                onClick={() => this.EditRound(list)}
                              />
                              &nbsp; &nbsp;
                              <img
                                src={Images.DeleteIcon}
                                alt={""}
                                onClick={() => this.DeleteRound(list._id)}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="align-center">
                            No Rounds Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                      */}
              </>
            
          </div>
        </div>
      </div>
    );
  }
}