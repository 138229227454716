import React, { Component } from "react";
import { Link } from "react-router";
import { browserHistory } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import "./index.scss";
import api from "../../utils/api";
import { Images } from "../../assets";
import Loader from "../../Components/Loader";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      msg: "",
      forgotPassword: false,
      error: "",
      error2: "",
      alert: "",
    };
    this.hideAlertSuccess = this.hideAlertSuccess.bind(this);
    this.hideAlertFailure = this.hideAlertFailure.bind(this);
  }

  sendResetLink(event) {
    event.preventDefault();
    if (this.refs.email_forgot_password.value === "") {
      this.setState({
        error: "Email is required field",
      });
    } else {
      event.preventDefault();
      const getfailureAlert = (msg) => (
        <SweetAlert danger title={msg} onConfirm={this.hideAlertFailure}>
          Try again!
        </SweetAlert>
      );
      const getSuccessAlert = (msg) => (
        <SweetAlert
          success
          title={msg}
          onConfirm={this.hideAlertSuccess}
        ></SweetAlert>
      );
      const email = this.refs.email_forgot_password.value;
      var postData = {
        email: email,
        resetPage: window.location.origin + "/ResetPassword",
      };

      api
        .post("admin/forgotpassword", postData)
        .then((res) => {
          this.setState({
            alert: getSuccessAlert(res.data.msg),
          });
        })
        .catch((err) => {
          this.setState({
            alert: getfailureAlert(err.response.data.msg),
          });
        });
    }
  }

  hideAlertSuccess() {
    browserHistory.push("/Login");
  }

  hideAlertFailure() {
    this.setState({
      alert: null,
    });
  }

  render() {
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">
          {" "}
          <i className="fa fa-info-circle"></i> {this.state.error}
        </p>
      ) : (
        <span />
      );
    return (
      <div class="container ForgotPass-page">
        <Loader />
        <div class="header">
          <div class="nav-container">
            <div className="container-fluid">
              <div className="navbar-header logo">
                <img src={Images.Logo} alt="" className="app-logo" />
              </div>
            </div>
          </div>
        </div>
        <div class="container ">
          <div class="hntr-boxed hntrAdmin_section">
            <div class="hntrAdmin_box">
              <div class="content-div">
                <div class="content-container">
                  <div className="login-container">
                    <div class="login">
                      <div>
                        {this.state.alert}
                        <form onSubmit={this.sendResetLink.bind(this)}>
                          <div>
                            <label className="sign-in">Forgot your password?</label>
                            <p>
                              Enter your email associated with your account and we will
                              send a link to reset your password.
                            </p>
                            <div className="control-group">
                              <input
                                className="form-elements"
                                ref="email_forgot_password"
                                type="email"
                                placeholder="sample@mycompany.com"
                              />
                            </div>
                          </div>
                          <div className="control-group">{errorMgs}</div>
                          <div className="form-button">
                            <button className="btn-success">Send Link</button>
                          </div>
                        </form>
                      </div>
                      <div className="link-forgot-password">
                        <p>Remember your password?</p>
                        <Link to="/Login">Login</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
