import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { Router, Route, browserHistory } from 'react-router';
import './index.css';
import App from './App';

//Transaction module
import Transactions from './Transactions/Transactions';
import TransactionDetails from './Transactions/TransactionDetails';
import Withdraws from './Transactions/CreateTransaction';

//Home module
import Countries from './Home/Countries'
import DailyInvestments from './Home/DailyInvestments'
import TokenSale from './Home/TokenSale';

//auth
import LoginPage from './Accounts/Login';
import ResetPassword from './Accounts/ResetPass';
import ForgotPassword from './Accounts/ForgotPass';
import ChangePassword from './Accounts/ChangePass';
import MyProfile from './Accounts/MyProfile';

//Users
import Users from './Users/Users';
import UserProfile from './Users/UserProfile';
import CreateUser from './Users/CreateUser';
import EditUser from './Users/EditUser';
import AddWithdraw from './Users/AddWithdraw';

//supports
import Support from './Support/Support';
import SupportDetails from './Support/SupportDetails';

//others
import ErrorPage from './404Notfound';
import Round from './Round';
import WhiteListUser from './Round/whitelistprivateuser';
import TierAllocationWallet from './Round/tier_allocationwallet';

//Tier
import Tier from './Tier/Tiers';
import CreateTier from './Tier/CreateTier';
import ViewTier from './Tier/ViewTier';

ReactDOM.render(

      <Router history={browserHistory}>
            <Route path="/" component={TokenSale}></Route>
            <Route path="/Home" component={TokenSale}></Route> 
            <Route path="/Home/Countries" component={Countries}></Route>
            <Route path="/Home/DailyInvestments" component={DailyInvestments}></Route>
            <Route path="/Users" component={Users}></Route>
            <Route path="/Users/AddWithdraw" component={AddWithdraw}></Route>
            <Route path="/Users/UserProfile/:userId" component={UserProfile}></Route>
            <Route path="/Users/CreateUser" component={CreateUser}></Route>
            <Route path="/Users/CreateTransaction/:userId" component={Withdraws}></Route>
            <Route path="/Users/EditUser/:userId" component={EditUser}></Route>
            <Route path="/Transactions" component={Transactions}></Route>
            <Route path="/Transactions/TransactionDetails/:HashId" component={TransactionDetails}></Route>
            <Route path="/Transactions/CreateTransaction" component={Withdraws}></Route>
            <Route path="/Transactions/CreateTransaction/:userId" component={Withdraws}></Route>
            <Route path="/Login" component={LoginPage}></Route>
            <Route path="/ForgotPassword" component={ForgotPassword}></Route>
            <Route path="/ResetPassword" component={ResetPassword}></Route>
            <Route path="/ChangePassword" component={ChangePassword}></Route>
            <Route path="/MyProfile" component={MyProfile}></Route>
            <Route path="/Support" component={Support}></Route>
            <Route path="/Rounds" component={Round}></Route>
            <Route path="/Rounds/WhiteListUsers" component={WhiteListUser}></Route>
            <Route path="/Rounds/TierAllocationWallet/:tierId" component={TierAllocationWallet}></Route>
            <Route path="/Support/:category_id/:user_id" component={SupportDetails}></Route>
            <Route path="/Tier" component={Tier}></Route>
            <Route path="/Tier/CreateTier" component={CreateTier}></Route>
            <Route path="/Tier/ViewTier/:tierId" component={ViewTier}></Route>

            {/* <Route path="/Support/SupportDetails" component={SupportDetails}></Route> */}
            <Route path='*'  component={ErrorPage} />
      </Router>
      , document.getElementById('root-new'));
registerServiceWorker();