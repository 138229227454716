import React, { Component } from "react";
import { Link } from "react-router";
import api from "../../utils/api";
import Header from "../../Components/Header";
import { Images } from "../../assets";
import Loader from "../../Components/Loader";
import "./index.scss";

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addClass: false,
      details: [],
      alert: null,
      msg: "",
      error: "",
    };
  }
  componentWillMount() {
    if (localStorage.getItem("Admin_token") == null) {
      window.location.href = "/Login";
    }
      this.getAdminDetails();
  }

  getAdminDetails = () => {
    api
      .get("admin/getDetails")
      .then((response) => {
        const details = response.data.data;
        this.setState({ details: details });
        console.log("det",details)
      })
      .catch((err) => {
        console.log("Admin Details Are not comming");
      });
  };

  render() {
    return (
      <div className="myProfile-page">
        {console.log("details",this.state.details)}
        <Header />
        <Loader />
        <div className="wrapper">
          <div className="myProfile">
            <h2>My Profile</h2>
            <div className="field-block">
              <div className="field-wrapper">
                <label>ID</label>
                <div className="field-data">{this.state.details?.id}</div>
              </div>
              <div className="field-wrapper">
                <label>NAME</label>
                <div className="field-data"> {this.state.details?.name}</div>
              </div>
            </div>
            <div className="field-block">
              <div className="field-wrapper">
                <label>EMAIL</label>
                <div className="field-data">{this.state.details?.email}</div>
              </div>
              <div className="field-wrapper">
                <label>PHONE</label>
                <div className="field-data">{this.state.details?.mobile}</div>
              </div>
            </div>
            <div className="field-wrapper">
              <label>Status</label>
              <div className={`"status" ${this.state.details?.active ? 'active' : 'disabled'}`}>{this.state.details?.active ? 'Active' : 'Disabled'}</div>
            </div>
            <div className="btn-wrapper">
                <Link className="btn-ok" to="/">OK</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyProfile;
