import React, { Component } from "react";
import Header from "../Components/Header";
import Loader from "../Components/Loader";
import { Images } from "../assets";
import { Link } from "react-router";
import api from "../utils/api.js";
import { browserHistory } from "react-router";
import Formats from "../utils/Formats";
import "./index.scss";
import moment from "moment";
import {serialize} from '../utils/function'
let pagination = "";
class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TransactionDetails: [],
      status: "all",
      pages: 0,
      indents: [],
      currentPage: 1,
    };
    this.pageChange = this.pageChange.bind(this);
  }

  componentDidMount() {
    this.getTransactionDetails();
  }

  pageChange = (pageNumber) => {
    this.setState(
      {
        currentPage: pageNumber,
      },
      () => {
        this.getTransactionDetails();
      }
    );
  };
  getTransactionDetails = () => {
    let param;
    this.state.status == "all" || this.state.status == ""?
    param = {
      page:this.state.currentPage,
    } 
    : param = {
      page:this.state.currentPage,
      status:this.state.status,
    }
    api
      .get(
        `transaction/getAllTransactions?${serialize(param)}`
      )
      .then((response) => {
        const TransactionDetails = response.data.data.transactions;
        const pages =
          Math.ceil(response.data.data.totalCount.totalCount / 10) > 1
            ? Math.ceil(response.data.data.totalCount.totalCount / 10)
            : 0;
        this.setState({ TransactionDetails: TransactionDetails, pages: pages });
      })
      .catch((err) => {
        console.log("something went wrong");
      });
  };

  ChangeStatus = (e) => {
    this.setState({ status: e.target.value,currentPage:1 }, () => {
      this.getTransactionDetails();
    });
  };

  refresh = () => {
    this.getTransactionDetails();
  };

  redirect(x) {
    browserHistory.push(`/Transactions/TransactionDetails/${x}`);
  }

  render() {
    let details = this.state.TransactionDetails;
    this.state.indents = [];
    {
      for (var i = 0; i < this.state.pages; i++) {
        this.state.indents.push(i);
      }

      this.state.pages > 1
        ? (pagination = (
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li
                  className={
                    this.state.currentPage === 1
                      ? "page-item none"
                      : "page-item"
                  }
                >
                  <a
                    className="page-link"
                    onClick={this.pageChange.bind(
                      this,
                      this.state.currentPage - 1
                    )}
                  >
                    Previous
                  </a>
                </li>

                {this.state.indents.map((item, index) => (
                  <li className="page-item">
                    <a
                      className={
                        this.state.currentPage === index + 1
                          ? "page-link active"
                          : "page-link"
                      }
                      onClick={this.pageChange.bind(this, index + 1)}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}
                <li
                  className={
                    this.state.currentPage === this.state.pages
                      ? "page-item none"
                      : "page-item"
                  }
                >
                  <a
                    className="page-link"
                    onClick={this.pageChange.bind(
                      this,
                      this.state.currentPage + 1
                    )}
                  >
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          ))
        : (pagination = <nav />);
    }
    return (
      <div className="Transaction-page">
        <Header />
        <Loader />
        <div className="wrapper">
          <div className="Transaction-head">
            <div className="Transaction-left">
              <h2>Select Type</h2>
              <div className="select-Type">
                <div>
                  <input
                    value="all"
                    group="Status"
                    defaultChecked
                    onChange={this.ChangeStatus.bind(this)}
                    name="Status"
                    type="radio"
                  />
                  <span
                    className={`label-text ${
                      this.state.status == "all" ? "active" : ""
                    }`}
                  >
                    All
                  </span>
                </div>
                <div>
                  <input
                    value="pending"
                    onChange={this.ChangeStatus.bind(this)}
                    group="Status"
                    name="Status"
                    type="radio"
                  />
                  <span
                    className={`label-text ${
                      this.state.status == "pending" ? "active" : ""
                    }`}
                  >
                    PENDING
                  </span>
                </div>
                <div>
                  <input
                    value="approved"
                    onChange={this.ChangeStatus.bind(this)}
                    group="Status"
                    name="Status"
                    type="radio"
                  />
                  <span
                    className={`label-text ${
                      this.state.status == "approved" ? "active" : ""
                    }`}
                  >
                    APPROVED
                  </span>
                </div>
                <div>
                  <input
                    value="rejected"
                    onChange={this.ChangeStatus.bind(this)}
                    group="Status"
                    name="Status"
                    type="radio"
                  />
                  <span
                    className={`label-text ${
                      this.state.status == "rejected" ? "active" : ""
                    }`}
                  >
                    REJECTED
                  </span>
                </div>
                <div>
                  <input
                    value="reversal"
                    onChange={this.ChangeStatus.bind(this)}
                    group="Status"
                    name="Status"
                    type="radio"
                  />
                  <span
                    className={`label-text ${
                      this.state.status == "reversal" ? "active" : ""
                    }`}
                  >
                    REVERSAL
                  </span>
                </div>
              </div>
            </div>

            <Link to="/Transactions/CreateTransaction">
              <div className="Transaction-right">
                {/* <img src={Images.ADD_ICON} alt={""} /> */}
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;Create
              </div>
            </Link>
          </div>
          <div className="Transaction">
            <div className="clearfix"></div>
            <h2>Transactions</h2>
            <div className="Transaction-table-wrapper">
              <table className="Transaction-tablet">
                <thead>
                  <tr>
                    <th className="align-left">User Id</th>
                    <th>Amount</th>
                    <th>ROUND</th>
                    <th>Date</th>
                    <th>From</th>
                    <th className={`${this.state.status !== "all" ? 'align-right' : ''}`}>Currency</th>
                    {this.state.status == "all" && <th className="align-right">Status</th>}
                  </tr>
                </thead>
                <tbody>
                  {details && details.length >0 ? (
                    details.map((details, i) => (
                      <tr
                        key={i}
                        onClick={() => this.redirect(details.transactionHash)}
                      >
                        <td className="hash align-left">{details.user_id}</td>
                        <td className="Amount">
                          {parseFloat(details.amount).toFixed(2)}
                          {"BUSD"}
                          <br />
                          <p>
                            ${details.amount}
                            {"USD"}
                          </p>
                        </td>
                        <td style={{textTransform:'capitalize'}}>{details.rounds}</td>
                        <td>
                          {details.update_date
                            ? moment(details.update_date).format("DD MMM, YYYY")
                            : moment(details.create_date).format(
                                "DD MMM, YYYY"
                              )}
                        </td>
                        <td>{details.fromAddress}</td>
                        <td  className={`Amount ${this.state.status !== "all" ? 'align-right' : ''}`}>
                          {details.amount}
                          {details.currency}
                          <br />
                          <p>
                            ${details.amount}
                            {"USD"}
                          </p>
                        </td>
                        {this.state.status == "all" && (
                          <td className={`${details.status} align-right`}>
                            {details.status}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : 
                    <tr>
                      <td colSpan="6" className="align-center" style={{fontSize:'16px'}}>
                        No Transactions Available
                      </td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
            <div className="align-pagination">
              {pagination}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Transactions;
