import React, { Component } from "react";
import { Link } from "react-router";
import Header from "../Components/Header";
import "./index.scss";
class ErrorPage extends Component {
  render() {
    return (
      <div className="Error-page">
        <div className="wrapper ">
          <Header />
          <div className="error flex-center">
            <h1>404</h1>
            <h2>Oops! Page Not Found</h2>
            <Link to="/">Back to Home</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
