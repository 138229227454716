import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../Components/Loader";
import Formats from "../utils/Formats";
import $ from "jquery";
import { Link } from "react-router";
class SupportDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: this.props.params.user_id,
      category_id: this.props.params.category_id,
      details: [],
      statueCheck: false,
      previousStatus: "",
      nextState: "",
      alert: null,
    };
  }

  componentDidMount() {
    this.getDetails();
  }

  getDetails = () => {
    api
      .get(
        `support/getDetails?user_id=${this.props.params.user_id}&category_id=${this.props.params.category_id}`
      )
      .then((response) => {
        const Details = response.data.data;
        this.setState({ Details: Details });
      })
      .catch((err) => {
        console.log("something went wrong support/getDetails :- ", err);
      });
  };

  render() {
    let details = this.state.Details;
    return (
      <div className="Support-page">
        <Header />
        <Loader />
        <div className="wrapper">
          <div className="Support-details-page">
            <h2>Query Details</h2>
            <div className="clearfix"></div>
            <div className="Support-details">
              {this.state.alert}
              {details ? (
                <div className="User-profile-details">
                  <div className="User-profile-details-left">
                    <div className="field-details">
                      <label>SUPPORT ID</label>
                      <div className="id">{details._id}</div>
                    </div>
                    <div className="field-details">
                      <label>USER ID</label>
                      <div>{details.user_id}</div>
                    </div>
                    <div className="field-details">
                      <label>USER EMAIL</label>
                      <div>{details.user.email}</div>
                    </div>
                    <div className="field-details">
                      <label>PHONE</label>
                      <div>{details.user.mobile}</div>
                    </div>
                    <div className="field-details">
                      <label>CATEROGY</label>
                      <div>{details.category}</div>
                    </div>
                  </div>
                  <div className="User-profile-details-right">
                    <div className="field-details">
                      <label>SUBJECT</label>
                      <div>{details.subject}</div>
                    </div>
                    <div className="field-details">
                      <label>MESSAGE</label>
                      <div>{details.message}</div>
                    </div>
                    <div className="field-details">
                      <label>DATE</label>
                      <div>
                        {" "}
                        {details.update_date
                          ? Formats.DateFormat(details.update_date)
                          : Formats.DateFormat(details.create_date)}
                      </div>
                    </div>
                    <div className="field-details">
                      <label>STATUS</label>
                      <div>{details.status}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              )}
              <div className="form-button">
                <button
                  onClick={api.LoadPopup.bind(this, "addReply")}
                  className="btn-create"
                >
                  Reply
                </button>
                <Link to="/Support">
                  <div className="Transaction-cancel">Cancel</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {details ? <AddReply myId={details._id} /> : <div />}
      </div>
    );
  }
}

class AddReply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      error: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
  }

  doAddReply = (event) => {
    if (this.refs.subject.value === "") {
      this.setState({
        error: "Status is required field",
      });
    } else if (this.refs.message.value === "") {
      //comments
      this.setState({
        error: "Message is required field",
      });
    } else {
      this.setState({
        error: "",
      });
      this.AddReply(event);
    }
  };

  AddReply(e) {
    const subject = this.refs.subject.value; 
    const message = this.refs.message.value;
    let data = {
      support_id: this.props.myId,
      subject: subject,
      message: message,
    };

    const getfailureAlert = () => (
      <SweetAlert danger title="Reply notSent !" onConfirm={this.hideAlert}>
        Try again!
      </SweetAlert>
    );

    const getSuccessAlert = () => (
      <SweetAlert
        success
        title="Reply  Send Successfully!"
        onConfirm={this.hideAlert}
      ></SweetAlert>
    );

    api
      .post("support/reply", data)
      .then((res) => {
        this.setState({
          alert: getSuccessAlert(),
        });
      })
      .catch((err) => {
        this.setState({
          alert: getfailureAlert(),
        });
      });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
    $(".popup").removeClass("show");
    this.refs.subject.value = "";
    this.refs.message.value ="";
  }

  HidePopup = (e) => {
    document.getElementById(e).classList.remove('show')
    this.refs.subject.value = "";
    this.refs.message.value ="";
  }

  render() {
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">{this.state.error}</p>
      ) : (
        <span />
      );
    return (
      <div>
        {this.state.alert}
        <div id="addReply" className="popup fade" role="dialog">
          <div className="ico-dialog">
            <div className="ico-content">
              <div className="profilePopup-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.HidePopup.bind(this, "addReply")}
                >
                  &times;
                </button>
                <h4 className="profilePopup-title">Add Reply</h4>
              </div>
              <div className="profilePopup-body">
                <label Htmlfor="subject">Subject* :</label>
                <input
                  type="text"
                  ref="subject"
                  id="subject"
                  className="form-control"
                  name="subject"
                /><br/>
                <label Htmlfor="message">Message* :</label>
                <textarea
                  ref="message"
                  id="message"
                  className="form-control"
                  name="message"
                ></textarea>
                {errorMgs}
              </div>
              <div className="profilePopup-footer">
                <button
                  type="button"
                  data-id={this.state.id}
                  onClick={this.doAddReply.bind(this)}
                  className="add-btn"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SupportDetails;
