import React, { Component } from "react";
import loader from "./../../loader.gif";
import api from "./../../utils/api";
import $ from "jquery";
import { Link } from "react-router";
import { browserHistory } from "react-router";
import "jquery-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import { Images } from "../../assets";
import Loader from "../../Components/Loader";
import "./index.scss";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      msg: "",
      forgotPassword: false,
      error: "",
      error2: "",
      rememberMe: "not-accepted",
    };
  }

  componentWillMount() {
    if (localStorage.getItem("Admin_token") != null) {
      window.location.href = "/";
    }
  }
  componentDidMount() {
    $(".ForgotLink").click(function () {
      $(".LoginCard").removeClass("show");
      $(".ForgotPasswordCard").addClass("show");
    });
    $(".LoginLink").click(function () {
      $(".ForgotPasswordCard").removeClass("show");
      $(".LoginCard").addClass("show");
    });
  }

  RememberMe(e) {
    let options = this.state.terms;
    if (e.target.checked) {
      options = "accepted";
    } else {
      options = "not-accepted";
    }
    this.setState({ rememberMe: options });
  }

  doLogin = (event) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this.refs.email.value === "") {
      this.setState({
        error: "Email is required field",
      });
    } else if (!this.refs.email.value.match(mailformat)) {
      this.setState({
        error: "Email Not Valid",
      });
    } else if (this.refs.password.value === "") {
      this.setState({
        error: "Password is required field",
      });
    } else {
      this.setState({
        error: "",
      });
      this.login(event);
    }
    event.preventDefault();
  };

  login(event) {
    event.preventDefault();
    const email = this.refs.email.value;
    const password = this.refs.password.value;
    var postData = {
      email: email,
      password: password,
    };
    api
      .post("admin/Login", postData)
      .then((response) => {
        localStorage.setItem("admin_email", email);
        localStorage.setItem(
          "Admin_profile",
          JSON.stringify(response.data.data)
        );
        localStorage.setItem("Admin_token", response.data.data.token);
        localStorage.setItem("ico_roles", response.data.data.roles);

        if (response.data.data.active && !response.data.data.locked) {
          window.location.href = '/';
        }
      })
      .catch((err) => {
        if (err.response) {
          this.setState({ msg: err.response.data.msg });
        }
      });
  }

  hideAlertSuccess() {
    window.location.href = "/Login";
  }
  hideAlertFailure() {
    this.setState({
      alert: null,
    });
  }

  render() {
    if (localStorage.getItem("Admin_token") != null) {
      return;
    }

    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">{this.state.error}</p>
      ) : (
        <span />
      );

    return (
      <div class="container">
        <Loader />
        <div class="header">
          <div class="nav-container">
            <div className="container-fluid">
              <div className="navbar-header logo">
                <img src={Images.Logo} alt="" className="app-logo" />
              </div>
            </div>
          </div>
        </div>
        <div class="container ">
          <div class="hntr-boxed hntrAdmin_section">
            <div class="hntrAdmin_box">
              <div class="content-div">
                <div class="content-container">
                  <div className="login-container">
                    <div className="login">
                      <form onSubmit={this.doLogin.bind(this)}>
                        <div className="LoginCard show">
                          <h2 className="sign-in">Login to your account</h2>
                          <div className="control-group">
                            <input
                              type="text"
                              className="form-control"
                              ref="email"
                              name="email"
                              placeholder="Email *"
                            />
                          </div>
                          <div className="control-group">
                            <input
                              type="password"
                              className="form-control"
                              ref="password"
                              name="password"
                              placeholder="Password *"
                            />
                          </div>
                          {errorMgs}
                          <div className="form-button">
                            <button type="submit" className="btn-login">
                              Login
                            </button>
                          </div>

                          <div className="forgot-pass">
                            <div className="animated-checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  name="rememberMe"
                                  class="rememberMe"
                                  id="rememberMe-checkbox"
                                  onChange={this.RememberMe.bind(this)}
                                />
                                <span className="label-text">Remember Me</span>
                              </label>
                            </div>
                            <div className="forgot-password">
                              <Link to="/ForgotPassword">Forgot Password?</Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {this.state.alert}
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
