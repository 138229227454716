import Web3 from "web3";
import util from '../utils/config';

let web3;
let ethereum;
let ABI = util.ABI;
let CONTRACT_ADDRESS = util.CONTRACT_ADDRESS;
class Web3provider 
{

    constructor()
    {
        this.oContract = null;
        this.sWalletAddress = null;
        this.init();
        
    }
    init()
    {
        this.handleClick().then($true=>{
            this.web3Connection().then(a=>{
                this.connectSmartContract();
            })
        })
    }
    handleClick = async () => {
        const openInNewTab = (url) => {
          const newWindow = window.open(url, "_blank", "noopener,noreferrer");
          if (newWindow) newWindow.opener = null;
        };
        // Check if MetaMask is installed
        if (!window.ethereum) {
          window.alert("Please install MetaMask first.");
          openInNewTab(
            "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en"
          );
          return;
        }
    
        if (!web3) {
          try {
            // Request account access if needed
            await window.ethereum.enable();
    
            // We don't know window.web3 version, so we use our own instance of Web3
            // with the injected provider given by MetaMask
            web3 = new Web3(window.ethereum);
          } catch (error) {
            window.alert("You need to allow MetaMask.");
            return;
          }
        }
    }

      async web3Connection() {
          let networkName,sWalletAddress;
        if (window.ethereum) {
            let ethereum =window.ethereum;
            window.web3 = new Web3(ethereum);
            try {
                // Request account access if needed
                web3 = new Web3(web3.currentProvider);
                
                var ee = await ethereum.request({
                    method: 'eth_requestAccounts'
                });
                   
                if (ee != undefined) {
                    
                    const provider = window['ethereum']; //user account address & network id 
                    
                    var network = provider.networkVersion;
                
                    if (network == undefined || network == null) {
                        try {
                            //   var networkTemp = await App.getNetwork();
                            //   network = networkTemp.result;
                        } catch (e) {
                            //toastr["error"]('<strong>Attention!</strong> MetaMask Not Found! Click & Install. <button class="btn btn-warning pull-center   btn-sm RbtnMargin" type="button" id="alert_btn"><a href="https://metamask.io/" target="_blank" style="color:Black;text-decoration: none !important;">Download MetaMask</a></button>');
                            console.log('<strong>Attention!</strong> MetaMask Not Found! Click & Install. <button class="btn btn-warning pull-center   btn-sm RbtnMargin" type="button" id="alert_btn"><a href="https://metamask.io/" target="_blank" style="color:Black;text-decoration: none !important;">Download MetaMask</a></button>');
                            return;
                        }
                    }
                    switch (network) {
                        case "1":
                            networkName = "MainNet";
                            break;
                        case "2":
                            networkName = "Morden";
                            break;
                        case "3":
                            networkName = "Ropsten";
                            break;
                        case "4":
                            networkName = "Rinkeby";
                            break;
                        case "42":
                            networkName = "Kovan";
                            break;
                        case "56":
                            networkName = "BSC Mainnet";
                            break;
                        default:
                            networkName = "Unknown";
                    }
    
                    if (web3 && network && ee != undefined) {
                        
                        let accounts = await web3.eth.getAccounts();
                        this.sWalletAddress = sWalletAddress = accounts[0].toString();
    
                        
                        var firstFive = sWalletAddress.slice(0, 10);
                        var lastFive = sWalletAddress.slice(sWalletAddress.length - 8, sWalletAddress.length);
                        if(!this.oContract)
                            this.oContract = new web3.eth.Contract(ABI, CONTRACT_ADDRESS);

                        return true;
         
                    } else if (web3 || network != 97 || ee == undefined) {
                        console.log('<strong>Attention!</strong> Please connect MetaMask on <b>BSC TestNet</b> You are on ' + networkName + '.');
                    }
                }
            } catch (error) {
                console.log(error);
                if (error.code == 4001) {
                    console.log('<strong>Attention!</strong> Please approve Metamask connection request to use our platform. <button class="btn btn-warning pull-center   btn-sm RbtnMargin" type="button" onclick="web3Connection()">Connect</button>');
                } else {
                    console.log('<strong>Attention!</strong> Please approve Metamask connection request to use our platform. <button class="btn btn-warning pull-center   btn-sm RbtnMargin" type="button" onclick="web3Connection()">Connect</button>');
                }
            }
        }
        // Non-dapp browsers...
        else {
            console.log('<strong>Attention!</strong> MetaMask Not Found! Click & Install. <button class="btn btn-warning pull-center   btn-sm RbtnMargin" type="button" id="alert_btn"><a href="https://metamask.io/" target="_blank" style="color:Black;text-decoration: none !important;">Download MetaMask</a></button>');
        }
    }


connectSmartContract()
{
    //this.oContract = new web3.eth.Contract(ABI, CONTRACT_ADDRESS);
  
}
 async getAmount()
{
    try
    {

        let nAdminCommissionPercentage = await this.oContract.methods.stableCoin().call();
        
    }
    catch(err)
    {
        throw(err);
    }
}
async createUpdatePreSale(params)
{
    let {tier_id,max_per_wallet,max_per_round,tierUtcStartDate,tierUtcEndDate,price,round_type,isAdminAllocation} = params;
    try
    {

        max_per_wallet = web3.utils.toWei(''+max_per_wallet);
        let start_time     = Math.round((tierUtcStartDate/1000));
        let end_time       = Math.round(((tierUtcEndDate)/1000));
        max_per_round  = web3.utils.toWei(''+max_per_round);
        price          = web3.utils.toWei(''+price);
        let isPrivate      = round_type || 0;
        isAdminAllocation = isAdminAllocation || 0; 
  
    let transHash =null;
    if(tier_id)
        console.log("Tier ID "+ tier_id + "Max Per Wallet " + max_per_wallet + "Start Time " +start_time + "End time " +end_time + " Max Per Round " +max_per_round + "Price " +price + "Is Private " +isPrivate+ "Admin Allocated " +isAdminAllocation);
    else 
        console.log("Create Called");

    if(tier_id)
    transHash = await this.oContract.methods.updatePreSaleTier(tier_id,max_per_wallet,start_time,end_time,max_per_round,price,isPrivate).send({'from':this.sWalletAddress});
    else   
    transHash = await this.oContract.methods.createPreSaleTier(max_per_wallet,start_time,end_time,max_per_round,price,isPrivate,isAdminAllocation).send({'from':this.sWalletAddress});
  
    return transHash;
    }
    catch(err)
    {
        throw(err);
    }
}
async getTierIDByblockHash(sTransactionHash)
{
    let receipt = await web3.eth.getTransactionReceipt(sTransactionHash);
    if (receipt === null)
        return undefined;
    if (receipt.status === true) {
        let receipt = await web3.eth.abi.decodeLog([{
                        type: 'uint256',
                        name: 'tier_id'
                                                    }]);
       return (receipt.logs[2] === undefined) ? receipt.logs[1].data : receipt.logs[2].data;
    }
    return undefined;
            
}
async createTierAlloc(params)
{
  
    let {tier_id,month,amount} =params;
        amount  = amount*100;
    try{

        let res = await this.oContract.methods.setDistributionPercent(tier_id,month,amount).send({'from':this.sWalletAddress});
        
        return res;
    }
    catch(err)
    {
        throw(err);
    }    
    
}
async whitelistUserAddress(params)
{
    let {tier_id,whitelistuser_address} =params;
    try{

        let res = await this.oContract.methods.whitelistAddress(whitelistuser_address,tier_id).send({'from':this.sWalletAddress});
    
        return res;
    }catch(err)
    {
        throw(err);
    }
    
}
async removeWhitelistUserAddress(params)
{
    let {tier_id,address} =params;
    try{

        let res = await this.oContract.methods.whitelistAddress(address,tier_id).send({'from':this.sWalletAddress});
     
        return res;
    }catch(err)
    {
        throw(err);
    }

}
async setVestingForTier(params)
{
    let {tier_id,start_time} =params;
    try{
        let startTime = Math.round((start_time/1000));
        console.log("Start Time is"+ start_time);
        let res = await this.oContract.methods.setVestingTimeForTier(tier_id,startTime).send({'from':this.sWalletAddress});
     
        return res;
    }catch(err){
       
        throw(err);
    }
    
}

async allocateUserWalletTokens(params)
{
    let {tierId, allocatedTo, numTokens} = params;
    numTokens  = web3.utils.toWei(''+numTokens);
    console.log("TierId " + tierId + " allocatedTo " + allocatedTo + " numTokens " + numTokens);
    try{
        let res = await this.oContract.methods.allocateVestingTokens(allocatedTo, tierId, numTokens).send({'from':this.sWalletAddress});
        return res;
    }catch(err){  
        throw(err);
    }
}

async removeUserWalletTokens(params)
{
    let {tierId, allocatedTo, numTokens} = params;
    numTokens  = web3.utils.toWei(''+numTokens);
    console.log("TierId " + tierId + " allocatedTo " + allocatedTo + " numTokens " + numTokens);
    try{
        let res = await this.oContract.methods.removeAllocatedVestingTokens(allocatedTo, tierId, numTokens).send({'from':this.sWalletAddress});
        return res;
    }catch(err){  
        throw(err);
    }
}


}
// try {
//     window.ethereum.on('accountsChanged', function (accounts) {
//         if (window.localStorage.removeItem('Authorization') != null)
//             logout();
//         else
//             window.location.href = '/';
//     });
//     window.ethereum.on('chainChanged', function () {
//         if (window.localStorage.removeItem('Authorization') != null)
//             logout();
//         else
//             window.location.href = '/';
//     });
// } catch (error) {
//     console.log(error);
// }
// var oContract = new web3.eth.Contract(abi, mainContractAddress);

// let nAdminCommissionPercentage = await oContract.methods.getAdminCommissionPercentage().call();
// console.log("nAdminCommissionPercentage: " + nAdminCommissionPercentage);

// let nEstimatedGasLimit = await oContract.methods.mintToken(parseInt(fd.nQuantity) > 1 ? true : false, fd.sHash, fd.sName, parseInt(fd.nQuantity), fd.sSetRroyalityPercentage, fd.sCollaborator, fd.nCollaboratorPercentage).estimateGas({
//     from: default_Address,
//     value: 1
// });
// console.log("nEstimatedGasLimit: " + nEstimatedGasLimit);

// let nGasPrice = parseInt(await web3.eth.getGasPrice());
// console.log("nGasPrice: " + nGasPrice);
export default Web3provider;

