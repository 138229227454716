import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";
import Loader from "../Components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import { browserHistory } from "react-router";
import "./index.scss";
import moment from "moment";
import { Images } from "../assets";
import RoundSubHeader from "../Components/Round-subheader";
import Web3provider from '../Helpers/Web3Provider';
import TierAllocationWallet from "./tier_allocationwallet";
import TierAllocation from './tier_allocation';
import TierVesting from './tier_vesting';
import WhiteListUser from './whitelistprivateuser';

let user;
class Round extends Component {
  constructor(props) {
    user = JSON.parse(localStorage.getItem("user_profile"));
    super(props);
    this.state = {
      alert: "",
      error: "",
      RoundDetails: [],
      selectedCategory: "",
      showTable: true,
      update: "",
      tier_vesting:false,
      tier:{},
      tier_id:"",
      tier_allocation:false,
      whitelistuser:false,
      addtoUserWallet:false,
      showLoader:false,
      posts: [],
      DataisLoaded: false
    };
    this.web3Client = new Web3provider();
    this.hideAlertSuccess = this.hideAlertSuccess.bind(this);
    this.hideAlertFailure = this.hideAlertFailure.bind(this);
    this.hideUpdateAlertSuccess = this.hideUpdateAlertSuccess.bind(this);
    this.TierAllocation   = this.TierAllocation.bind(this); 
    this.TierAllocationWallet = this.TierAllocationWallet.bind(this);
    this.tierValidated   = this.tierValidated.bind(this); 
    this.onTierAllocationOrVestingSuccess = this.onTierAllocationOrVestingSuccess.bind(this);
  }
  TierAllocation(list)
  {
    const id = list.tier_id;
    this.setState({...this.state,tier_id:id,tier_allocation:true});
  }
  TierAllocationWallet(list)
  {
    const id = list.tier_id;
    this.setState({...this.state,tier_id:id, addtoUserWallet:true});
  }
  whitelistUserAddress(list)
  {
    const id = list.tier_id;
    this.setState({...this.state,tier_id:id,whitelistuser:true});
  }
  setVestingForTier = (tier)=>
  {
    const id = tier.tier_id;
    this.setState({...this.state,tier_id:id,tier_vesting:true});
  }
  onTierAllocationOrVestingSuccess()
  {
    this.setState({...this.state,tier:{},tier_allocation:false,tier_vesting:false,whitelistuser:false,addtoUserWallet:false});
  }
  tierValidated()
  {
    if (this.refs.category.value === "") {
      this.setState({
        error: "Category is required field",
      });
    } else if (this.refs.startDate.value === "") {
      this.setState({
        error: "Start Date is required field",
      });
    } else if (this.refs.endDate.value === "") {
      this.setState({
        error: "End Date is required field",
      });
    } else if (this.refs.max_per_wallet.value === "") {
      this.setState({
        error: "Max Tokens Per Wallet is required field",
      });
    }
    else if (this.refs.max_per_round.value === "") {
      this.setState({
        error: "Max Tokens Per Round is required field",
      });
    }
    else if (this.refs.price.value === "") {
      this.setState({
        error: "Price for round is required field",
      });
    }else if ((new Date(this.refs.startDate.value) > new Date(this.refs.endDate.value)) || (new Date(this.refs.endDate.value) < new Date(this.refs.startDate.value))) {
      this.setState({
        error: "Start Date must be smaller than End Date",
      });
    }else return true;
  }

  CreateUpdateRound = async (event) => {

     if(this.tierValidated()===true) {
      this.setState({
        error: "",
      });
      const getfailureAlert = (msg) => (
        <SweetAlert danger title="Error" onConfirm={this.hideAlertFailure}>
          {msg}
        </SweetAlert>
      );
      const getSuccessAlert = (msg) => (
        <SweetAlert success title="Success!" onConfirm={this.hideAlertSuccess}>
          {msg}
        </SweetAlert>
      );
      const id = this.state.update._id;
      const round = this.refs.category.value;
      const start_date = new Date(this.refs.startDate.value);
      const end_date = new Date(this.refs.endDate.value);
      const max_per_wallet = this.refs.max_per_wallet.value;
      const max_per_round = this.refs.max_per_round.value;
      const price = this.refs.price.value;
      const round_type = this.refs.is_private.checked?1:0;
      const isAdminAllocation = this.refs.is_admin_alloc.checked?1:0;
      let tierUtcStartDate = moment.utc(start_date).valueOf();
      let tierUtcEndDate = moment.utc(end_date).valueOf();
      let tier_id = this.state.update.tier_id;
      var postData = {
        round: round,
        start_date: start_date,
        end_date: end_date,
        max_per_wallet,
        max_per_round,
        price,
        round_type,
        "isAdminAllocation":isAdminAllocation
      };
      console.log("postData", postData);
    // create update pre sale
      api.showLoader();
      this.setState({...this.state,showLoader:true});
 
      this.web3Client.createUpdatePreSale({tier_id,max_per_round,max_per_wallet,tierUtcStartDate,tierUtcEndDate,price,round_type,isAdminAllocation})
          .then(({transactionHash})=>{
              postData.sTransactionHash = transactionHash;
              return postData;
          })
          //.then(pData=>this.web3Client.getTierIDByblockHash(pData.sTransactionHash))
          // .then(tier_id=>{
       
          //        postData.tier_id = tier_id || "";
          //        return postData;
          // })
          .then(pData=>{
            api
            .post(id?`admin/updaterounds/${id}`:"admin/rounds", pData)
            .then((res) => {
              this.setState({...this.state,showLoader:false});
              this.setState({
                update:{},
                alert: getSuccessAlert(res.data.msg),
              });
            })
          })
          .catch((err) => {
            this.setState({...this.state,showLoader:false});
            api.hideLoader();
            this.setState({
              alert: getfailureAlert("Something went wrong"),
            });
          });
    
       // return getfailureAlert("Wallet transaction failed");
      
      // api
      //   .post(id?`admin/updaterounds/${id}`:"admin/rounds", postData)
      //   .then((res) => {
      //     this.setState({
      //       update:{},
      //       alert: getSuccessAlert(res.data.msg),
      //     });
      //   })
        // .catch((err) => {
        //   this.setState({
        //     alert: getfailureAlert(err.response.data.msg),
        //   });
        // });
    }
  };
  hideAlertSuccess() {
    this.setState({
      alert: null,
      showTable: true,
      update:'',
      showLoader:false,
    });
    this.getRounds();
    this.refs.category.value = "-1";
    this.refs.startDate.value = "";
    this.refs.endDate.value = "";
    this.refs.max_per_round.value = "";
    this.refs.max_per_wallet.value = "";
    this.refs.price.value = "";
    this.refs.is_private.checked = false;
    this.refs.is_admin_alloc.checked = false;
  }
  hideUpdateAlertSuccess() {
    this.setState({
      update: "",showTable:true,alert: null,
    });
    this.getRounds();
    this.refs.updateCategory.value = "-1";
    this.refs.updateStartDate.value = "";
    this.refs.updateEndDate.value = "";
  }
  hideAlertFailure() {
    this.setState({
      alert: null,
      showLoader:false,
    });
  }
  componentDidMount() {
    this.getRounds();
    this.getTierCategoryList();
  }
  componentDidUpdate(prevProps,prevState) {
    // call getRound function incase we found any tier in pending state
    //if(prevState.RoundDetails.)
    let active = this.state.RoundDetails.every(val=>val.sTransactionStatus==1);
    if(!active)
    {
      setTimeout(()=>{
      this.getRounds();
      this.getTierCategoryList();
      },2000)
    }
  }

  getTierCategoryList = () => {
    api.get(`tier/getTiers`).then((response) => {
        const posts = response.data.data.data;
        this.setState({
            posts: posts,
            DataisLoaded: true
        });
      }).catch((err) => { 
          console.log("sSomething went wrong");
      });
  };

  getTierStatus(status)
  {
    const _status = {1:"Completed","-1":"Failed",0:"Pending"};
    return _status[status] || "Pending"; 
  }
  getRounds = () => {
    api
      .get(`admin/getrounds`)
      .then((response) => {
        const Round = response.data.data;
        this.setState({
          RoundDetails: Round,
        });
      })
      .catch((err) => {
        console.log("something went wrong with Round Api :- ", err);
      });
  };

  EditRound = (d) => {
    this.setState({
      showTable: false,
      update: d,
    });
  };
  cancelUpdateRound=()=>{
    this.setState({
      showTable: true,
      update: '',
      showLoader:false,
    });
    
  }
 
  // updateRound = (event) => {
  //   if(this.tierValidated()===true) {
  //     this.setState({
  //       error: "",
  //     });
  //     const getfailureAlert = (msg) => (
  //       <SweetAlert danger title="Error" onConfirm={this.hideAlertFailure}>
  //         {msg}
  //       </SweetAlert>
  //     );
  //     const getSuccessAlert = (msg) => (
  //       <SweetAlert
  //         success
  //         title="Success!"
  //         onConfirm={this.hideUpdateAlertSuccess}
  //       >
  //         {msg}
  //       </SweetAlert>
  //     );
  //     const id = this.state.update._id;
  //     const round = this.refs.updateCategory.value;
  //     const start_date = this.refs.updateStartDate.value;
  //     const end_date = this.refs.updateEndDate.value;
  //     var postData = {
  //       round: round,
  //       start_date: start_date,
  //       end_date: end_date,
  //     };
  //     api
  //       .post(`admin/updaterounds/${id}`, postData)
  //       .then((res) => {
  //         this.setState({
  //           alert: getSuccessAlert(res.data.msg),
  //         });
  //       })
  //       .catch((err) => {
  //         this.setState({
  //           alert: getfailureAlert(err.response.data.msg),
  //         });
  //       });
  //   }
  // };

  DeleteRound = (id) => {
    if(!window.confirm("Are you sure you want to delete"))
    return;
    console.log("delete", id);
    const getfailureAlert = () => (
      <SweetAlert
        danger
        title="Round Deletion Unsuccessful!"
        onConfirm={this.hideAlertFailure}
      >
        Try again!
      </SweetAlert>
    );
    const getSuccessAlert = () => (
      <SweetAlert
        success
        title="Round Deletion  Successfully!"
        onConfirm={this.hideAlertSuccess}
      ></SweetAlert>
    );
    api
      .post(`admin/deleterounds/${id}`)
      .then((res) => {
        this.setState({
          alert: getSuccessAlert(),
        });
      })
      .catch((err) => {
        this.setState({
          alert: getfailureAlert(),
        });
      });
  };
  render() {
    if(this.state.tier_allocation)
      return <TierAllocation tier_id={this.state.tier_id} onSuccess={this.onTierAllocationOrVestingSuccess}/>
      if(this.state.addtoUserWallet)
      return <TierAllocationWallet tier_id={this.state.tier_id} onSuccess={this.onTierAllocationOrVestingSuccess}/>
      if(this.state.tier_vesting)
      return <TierVesting tier_id={this.state.tier_id} onSuccess={this.onTierAllocationOrVestingSuccess}/>  
      if(this.state.whitelistuser)
      return <WhiteListUser tier_id={this.state.tier_id} onSuccess={this.onTierAllocationOrVestingSuccess}/>
     
    let optionItems;
    let RoundDetails = this.state.RoundDetails;
    let showLoader   = this.state.showLoader; 
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">
          <i className="fa fa-info-circle"></i> {this.state.error}
        </p>
      ) : (
        <span />
      );
 
    return (
      <div className="Round-page">
        <Loader />
        <Header />
        
        <div className="wrapper">
          <div className="Round">
            <h2>Round</h2>
            {this.state.alert}
            {/* <p className="message">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p> */}
            {this.state.showTable ? (
              <>
                <div className="Round-form">
                  <div className="field-block">
                    <div className="field-wrapper">
                      <label>Category</label>
                      <select className="form-elements category" id="category" ref="category" name="category" >
                        <option value="-1" disabled> Select Category </option>
                        {this.state.posts.map((details, i) => (
                          <OptionRow key={i} myprop={details} refreshList={this.refresh} />
                        ))}

                        {/*
                        <option value="pre-seed">Pre-Seed</option>
                        <option value="seed">Seed</option>
                        <option value="pre-sale-whitelist">Pre-sale Whitelist</option>
                        <option value="pre-sale-public">Pre-sale Public</option>
                        */ }
                      </select>
                    </div>
                    
                    <div className="field-wrapper">
                      <label>START DATE</label>
                      <input
                        className="form-elements"
                        ref="startDate"
                        type="datetime-local"
                      />
                    </div>
                    <div className="field-wrapper">
                      <label>END DATE</label>
                      <input
                        className="form-elements"
                        ref="endDate"
                        type="datetime-local"
                      />
                    </div>
                    
                  </div>
                  <div className="field-block">
                    <div className="field-wrapper">
                      <label>Max tokens Per Wallet</label>
                      <input
                        className="form-elements"
                        ref="max_per_wallet"
                        type="number"
                      />
                    </div>
                    <div className="field-wrapper">
                      <label>Max tokens Per Tier</label>
                      <input
                        className="form-elements"
                        ref="max_per_round"
                        type="number"
                      />
                    </div>
                    <div className="field-wrapper">
                      <label>price</label>
                      <input
                        className="form-elements"
                        ref="price"
                        type="number"
                      />
                    </div>
                    </div>
                    <div className="field-block">
                    <div className="field-wrapper">
                      <label>Is Private</label>
                      <div className="form-check">
                      <input
                        className="form-elements form-check-input"
                        ref="is_private"
                        name="is_private"
                        type="radio"
                        id="is_private_y"
                      />&nbsp;<label htmlFor="is_private_y" className="form-check-label">Yes</label>
                      &nbsp;&nbsp;
                      <input
                        className="form-elements form-check-input"
                        name="is_private"
                        type="radio"
                        id="is_private_n"
                        defaultChecked={true}
                      />&nbsp;<label htmlFor="is_private_n" className="form-check-label">No</label>
                      </div>
                    </div>
                    <div className="field-wrapper">
                      <label>Is Admin Allocation</label>
                      <div className="form-check">
                      <input
                        className="form-elements form-check-input"
                        ref="is_admin_alloc"
                        name="is_admin_alloc"
                        type="radio"
                        id="is_admin_alloc_y"
                        
                      />&nbsp;<label htmlFor="is_admin_alloc_y" className="form-check-label">Yes</label>
                      &nbsp;
                      &nbsp;
                      <input
                        className="form-elements form-check-input"
                        name="is_admin_alloc"
                        type="radio"
                        id="is_admin_alloc_n"
                        defaultChecked={true}
                      />&nbsp;<label htmlFor="is_admin_alloc_n" className="form-check-label">No</label>
                    </div>
                    </div>
                    <div className="field-wrapper">&nbsp;</div>
                    </div>
                  <br />
                  
                  {errorMgs}
                  <div className="form-button">
                    <button
                      className="btn-save"
                      id="_profile_update"
                      onClick={this.CreateUpdateRound.bind(this)}
                      disabled={this.state.showLoader}
                      name="profileupdate"
                    >
                      Save
                    </button>
                    <button
                      className="btn-cancel"
                      id="_profile_update"
                      // onClick={this.CreateRound.bind(this)}
                      name="profileupdate"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <br />
                <div className="round-table-wrapper">
                  <h2>Round Details</h2>
                  <table className="round-table">
                    <thead>
                      <tr>
                        <th className="align-left">ROUND</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Tier ID</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {RoundDetails && RoundDetails.length > 0 ? (
                        RoundDetails.map((list, i) => (
                          <tr>
                            <td className="align-left">{list.rounds}</td>
                            <td data-label="Date">
                              <span className="Month uppercase">
                                {moment(list.start_date).format("DD MMM, YYYY HH:mm")}
                              </span>
                            </td>
                            <td data-label="Date">
                              <span className="Month uppercase">
                                {moment(list.end_date).format("DD MMM, YYYY HH:mm")}
                              </span>
                            </td>
                            <td data-label="Date">
                              <span className="Month uppercase">
                                {list.tier_id}
                              </span>
                            </td>
                            <td data-label="Date">
                              <span className="Month uppercase">
                                {this.getTierStatus(list.sTransactionStatus)}
                              </span>
                            
  
                            </td>
                            <td className="action-btns">
                             {list.tier_id? 
                            <img
                                src={Images.Tiervesting}
                                alt={"Tier Vesting"}
                                title={"Tier Vesting"}
                                onClick={() => this.setVestingForTier(list)}
                              />
                             :null} 
                              &nbsp; &nbsp;
                              {(list.tier_id && list.is_admin_allocation)?  
                            <img
                                src={Images.Tierdistribution}
                                alt={"Tier Allocation"}
                                title={"Tier Allocation/Distribution"}
                                onClick={() => this.TierAllocation(list)}
                              />
                              :null}
                              &nbsp; &nbsp;
                              {(list.is_admin_allocation == 1)? 
                              <a href={"/Rounds/TierAllocationWallet/"+list.tier_id}>
                              <img src={Images.TierWalletdistribution} alt={"Tier Allocation to Wallet"} title={"Tier Allocation to Wallet"} />
                            </a>
                              :null}
                              &nbsp; &nbsp;
                              {(list.tier_id && list.round_type==1)?
                              <img
                                src={Images.whitelist}
                                alt={"Whitelist User Address"}
                                title={"Whitelist User Address"}
                                onClick={() => this.whitelistUserAddress(list)}
                              />
                              :null}
                              &nbsp; &nbsp;
                              <img
                                src={Images.EditIcon}
                                alt={" Edit Tier"}
                                title={" Edit Tier"}
                                onClick={() => this.EditRound(list)}
                              />
                              &nbsp; &nbsp;
                              <img
                                src={Images.DeleteIcon}
                                alt={"Delete Tier"}
                                title={"Delete Tier"}
                                onClick={() => this.DeleteRound(list._id)}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="align-center">
                            No Rounds Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="Round-form">
                <div className="field-block">
                  <div className="field-wrapper">
                    <label>Category</label>
                    <select
                      className="form-elements category"
                      id="category"
                      ref="category"
                      name="category"
                    >
                      <option value="-1" disabled> Select Category </option>

                      {this.state.posts.map((details, i) => (
                        <option value={details.key} selected={this.state.update.rounds === details.key} >{details.name}</option>
                        ))}

                      {/* <option
                        value="pre-seed"
                        selected={this.state.update.rounds === "pre-seed"}
                      >
                        Pre-Seed
                      </option>
                      <option
                        value="seed"
                        selected={this.state.update.rounds === "seed"}
                      >
                        Seed
                      </option> */}
                     {/* 
                      <option
                        value="pre-sale-whitelist"
                        selected={
                          this.state.update.rounds === "pre-sale-whitelist"
                        }
                      >
                        Pre-sale Whitelist
                      </option>
                      <option
                        value="pre-sale-public"
                        selected={
                          this.state.update.rounds === "pre-sale-public"
                        }
                      >
                        Pre-sale Public
                      </option> */ }
                    </select>
                  </div>
                  <div className="field-wrapper">
                    <label>START DATE</label>
                    <input
                      className="form-elements"
                      ref="startDate"
                      type="datetime-local"
                      defaultValue={moment.utc(this.state.update.start_date).local().format('YYYY-MM-DDTHH:mm')}
                    />
                  </div>
                  <div className="field-wrapper">
                    <label>END DATE</label>
                    <input
                      className="form-elements"
                      ref="endDate"
                      type="datetime-local"
                      defaultValue={moment.utc(this.state.update.end_date).local().format('YYYY-MM-DDTHH:mm')}
                    />
                  </div>
                </div>
                <div className="field-block">
                    <div className="field-wrapper">
                      <label>Max tokens Per Wallet</label>
                      <input
                        className="form-elements"
                        ref="max_per_wallet"
                        type="number"
                        defaultValue={this.state.update.max_per_wallet}
                      />
                    </div>
                    <div className="field-wrapper">
                      <label>Max tokens Per Tier</label>
                      <input
                        className="form-elements"
                        ref="max_per_round"
                        type="number"
                        defaultValue={this.state.update.max_per_round}
                      />
                    </div>
                    <div className="field-wrapper">
                      <label>price</label>
                      <input
                        className="form-elements"
                        ref="price"
                        type="number"
                        defaultValue={this.state.update.price}
                      />
                    </div>
                    </div>
                   <div className="field-block">
                    <div className="field-wrapper">
                      <label>Is Private</label>
                      <div className="form-check">
                      <input
                        className="form-elements form-check-input"
                        ref="is_private"
                        name="is_private"
                        type="radio"
                        id="is_private_y"
                        defaultChecked={this.state.update.round_type==1?true:false}
                      />&nbsp;<label htmlFor="is_private_y" className="form-check-label">Yes</label>
                      &nbsp;&nbsp;
                      <input
                        className="form-elements form-check-input"
                        name="is_private"
                        type="radio"
                        id="is_private_n"
                        defaultChecked={this.state.update.round_type==0?true:false}
                      />&nbsp;<label htmlFor="is_private_n" className="form-check-label">No</label>
                      </div>
                    </div>
                    <div className="field-wrapper">
                      <label>Is Admin Allocation</label>
                      <div className="form-check">
                      <input
                        className="form-elements form-check-input"
                        ref="is_admin_alloc"
                        name="is_admin_alloc"
                        type="radio"
                        id="is_admin_alloc_y"
                        disabled="disabled"
                        defaultChecked={this.state.update.is_admin_allocation==1?true:false}
                      />&nbsp;<label htmlFor="is_admin_alloc_y" className="form-check-label">Yes</label>
                      &nbsp;
                      &nbsp;
                      <input
                        className="form-elements form-check-input"
                        name="is_admin_alloc"
                        type="radio"
                        id="is_admin_alloc_n"
                        disabled="disabled"
                        defaultChecked={this.state.update.is_admin_allocation==0?true:false}
                      />&nbsp;<label htmlFor="is_admin_alloc_n" className="form-check-label">No</label>
                    </div>
                    </div>
                    <div className="field-wrapper">&nbsp;</div>
                    </div>
                   
                <br />
                {errorMgs}
                <div className="form-button">
                  <button
                    className="btn-save"
                    id="_profile_update"
                    onClick={this.CreateUpdateRound.bind(this)}
                    name="profileupdate"
                    disabled={this.state.showLoader}
                  >
                    Update
                  </button>
                  <button
                    className="btn-cancel"
                    id="_profile_update"
                    onClick={this.cancelUpdateRound.bind(this)}
                    name="profileupdate"
                    disabled={this.state.showLoader}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class OptionRow extends Component {
  constructor(props) {
      super(props);
  };
  render() {
      let details = this.props.myprop;
      return (
          <option value={details.key}>{details.name}</option>
      );
  }
}




export default Round;
