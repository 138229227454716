import React, { Component } from "react";
import Header from "../Components/Header";
import Loader from "../Components/Loader";
import api from "../utils/api.js";
import moment from "moment";
import HomeHeader from "../Components/Home-header";

class DailyInvestments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    this.getDailyInvestments();
  }

  getDailyInvestments = () => {
    api
      .get("analytics/getInvestmentByDate")
      .then((response) => {
        const posts = response.data.data;
        this.setState({ posts });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  render() {
    let BUSD = 0,
      BUSD_Value = 0,
      BUSD_Total = 0,
      BUSD_Value_Total = 0,
      USD_AMT_Total = 0;

    if (this.state.posts) {
      this.state.posts.map((details, i) => {
        details.currency.map((list, i) => {
          BUSD_Total += list.amount
        });
      });
    }
    return (
      <div className="Home-Page">
        <Header />
        <HomeHeader />
        <Loader />
        <div className="wrapper">
          <div className="Home">
            <div className="clearfix"></div>

            <div className="table-wrapper">
              <h2>Daily Investments</h2>
              <table className="daily-investment-table">
                <thead>
                  <tr>
                    <th className="align-left">Date</th>
                    <th className="align-right">BUSD/USD</th>
                    {/* <th className="align-right">TOTAL USD</th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.posts ? (
                    this.state.posts.map((details, i) => (
                      <tr key={i}>
                        <td  className="align-left">
                          {moment(details.date).format("DD MMM, YYYY")}
                        </td>
                        <td className="align-right">
                          {
                            (BUSD = details.currency.filter((list) => {
                              return list.currency === "BUSD";
                            })[0]
                              ? parseFloat(details.currency.filter((list) => {
                                  return list.currency === "BUSD";
                                })[0].amount).toFixed(2)
                              : 0.0)
                          }{" "}
                          /
                          {
                            (BUSD_Value = details.currency.filter((list) => {
                              return list.currency === "BUSD";
                            })[0]
                              ? parseFloat(details.currency.filter((list) => {
                                  return list.currency === "BUSD";
                                })[0].amount).toFixed(2)
                              : 0.0)
                          }
                        </td>
                        {/* <td  className="align-right">{details.amount ? details.amount : 0.0}</td> */}
                      </tr>
                    ))
                  ) : (
                    <tr />
                  )}
                </tbody>
                <br />
                <tfoot>
                  <tr>
                    <td className="bold align-left">Total</td>
                    <td className="bold align-right">
                      {parseFloat(BUSD_Total).toFixed(2)} / {BUSD_Value_Total ? parseFloat(BUSD_Value_Total).toFixed(2) : parseFloat(BUSD_Total).toFixed(2)}
                    </td>
                    {/* <td className="bold align-right">{USD_AMT_Total}</td> */}
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DailyInvestments;
