import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";
import Loader from "../Components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import $ from "jquery";
import { browserHistory } from "react-router";
import { Link } from "react-router";
import "./index.scss";

class CreateTier extends Component {
  render() {
    return (
      <div className="Tier-page">
        <Header />
        <Loader />
        <div className="wrapper">
          <TierEdit tierId={this.props.params.tierId} />
        </div>
      </div>
    );
  }
}

class TierEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: null,
      alert: null,
      msg: "",
      error: "",
    };
  }
  callThis = (e) => {
    this.setState({ selectValueSalutation: e.target.value });
  };
  change = (e) => {
    this.setState({ selectGender: e.target.value });
  };


  docreateTier = (event) => {
    if (this.refs.name.value === "") {
      this.setState({
        error: "Name is required field",
      });
    } else if (this.refs.name.value.match(/^ *$/) !== null) {
      this.setState({
        error: "Name is required field",
      });
    } else {
      this.setState({
        error: "",
      });
      this.createTier(event);
    }
  };

  createTier(event) {
    const getfailureAlert = (msg) => (
      <SweetAlert danger title={msg} onConfirm={this.hideAlertFailure}>
        Try again!
      </SweetAlert>
    );
    const getSuccessAlert = (msg) => (
      <SweetAlert
        success
        title={msg}
        onConfirm={this.hideAlertSuccess}
      ></SweetAlert>
    );
    const name = this.refs.name.value;
    const tier_order = this.refs.tier_order.value;
    const key = name.toLowerCase();
    const url = key.replace(/ /g, "-");

    // const dob = this.refs.dob.value;

    var postData = {
      name: name,
      key: url,
      order: tier_order
    };
    console.log(postData);
    api.post("tier/insertTier", postData).then((res) => {
        this.setState({
          alert: getSuccessAlert(res.data.msg),
        });
      }).catch((err) => {
        this.setState({
          alert: getfailureAlert(err.response.data.msg),
        });
      });
  }
  hideAlertSuccess() {
    browserHistory.push(`/Tier`);
  }
  hideAlertFailure() {
    this.setState({
      alert: null,
    });
    $("input").val("");
  }
  render() {
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">{this.state.error}</p>
      ) : (
        <span />
      );
    return (
      <div className="create-Tier-wrapper">
        {this.state.alert}
        <div className="clearfix"></div>
        <div className="Tiers">
          <h2>Create Tier Label</h2>

          <div className="field-block">
            <div className="field-wrapper">
              <label htmlFor="name">Tier Name * :</label>
              <div className="name-block">
                <div className="name-width">
                  <input type="text" ref="name" id="name" className="form-control" name="name" autoFocus />
                </div>
              </div>
            </div>
          </div>
          <div className="field-block">
            <div className="field-wrapper">
              <label htmlFor="name">Tier Label ID * :</label>
              <div className="name-block">
                <div className="name-width">
                  <input type="number" ref="tier_order" min="1" id="tier_order" className="form-control" name="tier_order" autoFocus />
                </div>
              </div>
            </div>
          </div>
          
          {errorMgs}
          <div className="form-button">
            <button
              type="submit"
              onClick={this.docreateTier.bind(this)}
              className="btn-create"
            >
              Create Tier Label
            </button>
            <Link to="/Tier">
              <div className="Transaction-cancel">Cancel</div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default CreateTier;