import React,{Component} from "react";

export default class TierAllocationHtml extends Component
{
    constructor(props)
    {
        super(props);
        
    }
    render()
    {
        
        return(<div className="field-block">
        
        
        <div className="field-wrapper">
          <label>Month</label>
          <input
            className="form-elements"
            name="month"
            max="36"
            min="1"
            type="number"
            value={this.props.month}
            onChange={e=>this.props.updateOnChange(e,this.props.index)}
            
          />
        </div>
        <div className="field-wrapper">
          <label>Amount (in Percentage) </label>
          <input
            className="form-elements"
            name="amount"
            type="number"
            value={this.props.amount}
            onChange={e=>this.props.updateOnChange(e,this.props.index)}
            
          />
        </div>
        <div className="field-wrapper">
          {this.props.index>0?<button className="btn btn-link" onClick={e=>this.props.deleteAllocationHtml(this.props.index)}>X</button>:null}
        </div>
        
      </div>
      );
    }
}