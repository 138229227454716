import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";
import Loader from "../Components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import $ from "jquery";
import { browserHistory } from "react-router";
import { Link } from "react-router";

class CreateUser extends Component {
  render() {
    return (
      <div className="Users-page">
        <Header />
        <Loader />
        <UserEdit userId={this.props.params.userId} />
      </div>
    );
  }
}

class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: null,
      alert: null,
      selectValueSalutation: "Mr",
      selectGender: "male",
      changeselectCountry: "None",
      msg: "",
      countries: [],
      countrySelected: "None",
      salutationSelected: "mr",
      selectWhiteList:false,
      error: "",
    };
  }
  callThis = (e) => {
    this.setState({ selectValueSalutation: e.target.value });
  };
  callCountry = (e) => {
    this.setState({ changeselectCountry: e.target.value });
  };

  change = (e) => {
    this.setState({ selectGender: e.target.value });
  };
  whiteList = (e) => {
    this.setState({ selectWhiteList: !this.state.selectWhiteList });
  };

  componentDidMount() {
    this.getCountries();
  }
  getCountries = () => {
    api
      .get(`user/getCountryList`)
      .then((response) => {
        const countries = response.data.data;
        this.setState({
          countries: countries,
        });
      })
      .catch((err) => {
        console.log("something went wrong with countries Api");
      });
  };

  docreateUser = (event) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var phoneno = /^\d{4,12}$/;
    if (this.refs.name.value === "") {
      this.setState({
        error: "Name is required field",
      });
    } else if (this.refs.name.value.match(/^ *$/) !== null) {
      this.setState({
        error: "Name is required field",
      });
    } else if (this.refs.email.value === "") {
      this.setState({
        error: "Email is required field",
      });
    } else if (!this.refs.email.value.match(mailformat)) {
      this.setState({
        error: "Email is Not Valid",
      });
    } else if (this.refs.password.value === "") {
      this.setState({
        error: "Password is required field",
      });
    } else if (this.refs.password.value.length < 8) {
      this.setState({
        error: "Password should be min 8 characters long",
      });
    } else if (this.refs.password.value !== this.refs.confirmPassword.value) {
      this.setState({
        error: "Confirm Password should Match with Password",
      });
    } else if (this.refs.phoneNumber.value === "") {
      this.setState({
        error: "Phone is Required field",
      });
    } else if (!this.refs.phoneNumber.value.match(phoneno)) {
      this.setState({
        error: "Phone No  is Not Valid",
      });
    } else if (this.state.changeselectCountry === "None") {
      this.setState({
        error: "Please select Country",
      });
    } else if (this.refs.address.value.match(/^ *$/) !== null) {
      this.setState({
        error: "Address is required field",
      });
    } else {
      this.setState({
        error: "",
      });
      this.createUser(event);
    }
  };

  createUser(event) {
    const getfailureAlert = (msg) => (
      <SweetAlert danger title={msg} onConfirm={this.hideAlertFailure}>
        Try again!
      </SweetAlert>
    );
    const getSuccessAlert = (msg) => (
      <SweetAlert
        success
        title={msg}
        onConfirm={this.hideAlertSuccess}
      ></SweetAlert>
    );
    const name = this.refs.name.value;
    const email = this.refs.email.value;
    const password = this.refs.password.value;
    const mobile = this.refs.phoneNumber.value;
    const address = this.refs.address.value;
    const country = this.state.changeselectCountry;
    const salutation = this.state.selectValueSalutation;
    const gender = this.state.selectGender;
    const WhiteList = this.state.selectWhiteList;
    // const dob = this.refs.dob.value;

    var postData = {
      email: email,
      password: password,
      name: name,
      mobile: mobile,
      wallet: address,
      country: country,
      salutation: salutation,
      gender: gender,
      isWhiteList:WhiteList,
      resetPwdPage: window.location.origin + "/ResetPassword",
    };
    console.log(postData);
    api
      .post("user/create", postData)
      .then((res) => {
        this.setState({
          alert: getSuccessAlert(res.data.msg),
        });
      })
      .catch((err) => {
        this.setState({
          alert: getfailureAlert(err.response.data.msg),
        });
      });
  }

  hideAlertSuccess() {
    browserHistory.push(`/Users`);
  }
  hideAlertFailure() {
    this.setState({
      alert: null,
    });
    $("input").val("");
  }

  render() {
    let countries = this.state.countries;
    let optionItems = countries.map((country) => (
      <option key={country} value={country}>
        {country}
      </option>
    ));
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">{this.state.error}</p>
      ) : (
        <span />
      );
    return (
      <div className="create-user-wrapper">
        {this.state.alert}
        <div className="clearfix"></div>
        <div className="Users">
          <h2>Create User</h2>

          <div className="field-block">
            <div className="field-wrapper">
              <label htmlFor="name">Name * :</label>
              <div className="name-block">
                <select
                  className="form-control _prefixes valid"
                  onChange={this.callThis}
                  name="prefixes"
                  id="_prefixes"
                >
                  <option value="mr">Mr</option>
                  <option value="mrs">Mrs</option>
                  <option value="ms">Ms</option>
                </select>

                <div className="name-width">
                  <input
                    type="text"
                    ref="name"
                    id="name"
                    className="form-control"
                    name="name"
                    autoFocus
                  />
                </div>
              </div>
            </div>
            <div className="field-wrapper">
              <label htmlFor="email">Email * :</label>
              
              <input
                type="email"
                ref="email"
                
                className="form-control"
                name="email"
                id="email"
                autoComplete="email"
              />
              
            </div>
          </div>
          <div className="field-block">
            <div className="field-wrapper">
              <label htmlFor="password">Password * :</label>
              <input
                type="password"
                ref="password"
                autoComplete="off"
                className="form-control"
                name="password"
              />
            </div>
            <div className="field-wrapper">
              <label htmlFor="confirmPassword">Confirm Password * :</label>
              <input
                type="password"
                ref="confirmPassword"
                className="form-control"
                name="confirmPassword"
              />
            </div>
          </div>
          <div className="field-block">
            <div className="field-wrapper">
              <label htmlFor="phoneNumber">Phone Number * :</label>
              <input
                type="number"
                ref="phoneNumber"
                id="phoneNumber"
                className="form-control"
                name="phoneNumber"
              />
            </div>
            <div className="field-wrapper">
              <label htmlFor="country">Country :</label>
              <select
                className="form-control _country valid"
                onChange={this.callCountry}
                name="country"
                id="_country"
              >
                <option value="">None</option>
                {optionItems}
              </select>
            </div>
          </div>
          <div className="field-block1">
            <label htmlFor="addresss">Address:</label>
            <input
              type="text"
              ref="address"
              id="addresss"
              className="form-control"
              name="addresss"
            />
          </div>
          <div className="field-block">
            <div className="form-group">
              <label className="control-label">GENDER</label>
              <div className="inline-radio">
                <input
                  value="male"
                  group="gender"
                  checked={this.state.selectGender == "male" ? true : false}
                  onChange={this.change.bind(this)}
                  id="male"
                  name="gender"
                  type="radio"
                />
                <span className="label-text">Male</span>

                <input
                  value="female"
                  id="female"
                  checked={this.state.selectGender == "female" ? true : false}
                  onChange={this.change.bind(this)}
                  group="gender"
                  name="gender"
                  type="radio"
                />
                <span className="label-text">Female</span>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label">WHITELIST</label>
              <div className="inline-radio">
                <input
                  onChange={this.whiteList.bind(this)}
                  type="radio"
                  value={true}
                  checked={this.state.selectWhiteList === true ? true : false}
                />
                <span className="label-text">Yes</span>

                <input
                  onChange={this.whiteList.bind(this)}
                  type="radio"
                  value={false}
                  checked={this.state.selectWhiteList === false ? true : false}
                />
                <span className="label-text">No</span>
              </div>
            </div>
          </div>
          {errorMgs}
          <div className="form-button">
            <button
              type="submit"
              onClick={this.docreateUser.bind(this)}
              className="btn-create"
            >
              Create User
            </button>
            <Link to="/Users">
              <div className="Transaction-cancel">Cancel</div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default CreateUser;
