import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";
import Loader from "../Components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import $ from "jquery";
import { browserHistory } from "react-router";
import { Link } from "react-router";

class ViewTier extends Component {
  render() {
    return (
      <div className="Tier-page">
        <Header />
        <Loader />
        <div className="wrapper">
          <TierEdit tierId={this.props.params.tierId} />
        </div>
      </div>
    );
  }
}

class TierEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: null,
      alert: null,
      msg: "",
      error: "",
    };
  }
  callThis = (e) => {
    this.setState({ selectValueSalutation: e.target.value });
  };
  change = (e) => {
    this.setState({ selectGender: e.target.value });
  };

  componentDidMount() {
    this.gettierDetails();
  }

  refresh = () => {
    this.getUserDetails();
  };
  
  gettierDetails = () => {
    api
      .get(`tier/gettierDetails?tier_id=${this.props.tierId}`)
      .then((response) => {
        const details = response.data.data;
        this.setState({ details });
      })
      .catch((err) => {
        console.log("Error something went wrong"+err);
      });
  };

  doeditTier = (event) => {
    if (this.refs.name.value === "") {
      this.setState({
        error: "Name is required field",
      });
    } else if (this.refs.name.value.match(/^ *$/) !== null) {
      this.setState({
        error: "Name is required field",
      });
    } else {
      this.setState({
        error: "",
      });
      this.updateTier(event);
    }
  };

  updateTier(event) {
    const getfailureAlert = (msg) => (
      <SweetAlert danger title={msg} onConfirm={this.hideAlertFailure}>
        Try again!
      </SweetAlert>
    );
    const getSuccessAlert = (msg) => (
      <SweetAlert
        success
        title={msg}
        onConfirm={this.hideAlertSuccess}
      ></SweetAlert>
    );
    const name = this.refs.name.value;
    const id = this.refs.tier_id.value;
    const tier_order = this.refs.tier_order.value;
    var postData = {
        id : id,
        name: name,
        order:tier_order
    };
    console.log(postData);
    api.post("tier/updateTier", postData).then((res) => {
        this.setState({
          alert: getSuccessAlert(res.data.msg),
        });
      }).catch((err) => {
        this.setState({
          alert: getfailureAlert(err.response.data.msg),
        });
      });
  }
  hideAlertSuccess() {
    browserHistory.push(`/Tier`);
  }
  hideAlertFailure() {
    this.setState({
      alert: null,
    });
    $("input").val("");
  }
  render() {
      let new_details = this.state.details;
      let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">{this.state.error}</p>
      ) : (
        <span />
      );
    return (
      <div className="create-Tier-wrapper">
        {this.state.alert}
        <div className="clearfix"></div>
        <div className="Tiers">
          <h2>Update Tier Label</h2>
          {new_details !== null ? (
              <>
                <div className="field-block">
                    <div className="field-wrapper">
                    <label htmlFor="name">Tier Name* :</label>
                    <div className="name-block">
                        <div className="name-width">
                        <input type="hidden" ref="tier_id" id="tier_id"  defaultValue = { new_details._id } name="tier_id" />
                        <input type="text" ref="name" id="name"  defaultValue= { new_details.name } className="form-control" name="name" autoFocus />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="field-block">
                    <div className="field-wrapper">
                    <label htmlFor="name">Tier Label ID* :</label>
                    <div className="name-block">
                        <div className="name-width">
                        <input type="number" ref="tier_order" id="tier_order"  min="1"  defaultValue= { new_details.order } className="form-control" name="tier_order" autoFocus />
                        </div>
                    </div>
                    </div>
                </div>
                
                {errorMgs}
                <div className="form-button">
                    <button
                    type="submit"
                    onClick={this.doeditTier.bind(this)}
                    className="btn-create btn-update"
                    >
                    Update Tier Label
                    </button>
                    <Link to="/Tier">
                    <div className="Transaction-cancel">Cancel</div>
                    </Link>
                </div>
                </>
            ) : (
                <div />
              )}
        </div>
      </div>
    );
        
    }
 /* } */
}
export default ViewTier;