import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";
import Loader from "../Components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import $ from "jquery";
import { browserHistory } from "react-router";
import "./index.scss";
import { Link } from "react-router";

class EditUser extends Component {
  render() {
    return (
      <div className="Users-page">
        <Header />
        <Loader />
        <UserEdit userId={this.props.params.userId} />
      </div>
    );
  }
}

class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: null,
      alert: null,
      selectValueSalutation: "Mr",
      selectGender: "male",
      selectWhiteList: false,
      changeselectCountry: "None",
      msg: "",
      countries: [],
      countrySelected: "None",
      salutationSelected: "mr",
      error: "",
    };
    this.hideAlertSuccess = this.hideAlertSuccess.bind(this);
  }
  callThis = (e) => {
    this.setState({ selectValueSalutation: e.target.value });
  };
  callCountry = (e) => {
    this.setState({ changeselectCountry: e.target.value });
  };

  change = (e) => {
    this.setState({ selectGender: e.target.value });
  };
  whiteList = (e) => {
    this.setState({ selectWhiteList: !this.state.selectWhiteList });
  };

  componentDidMount() {
    this.getUserDetails();
    this.getCountries();
  }
  getCountries = () => {
    api
      .get(`user/getCountryList`)
      .then((response) => {
        const countries = response.data.data;
        this.setState({
          countries: countries,
          changeselectCountry: response.data.data.country,
        });
      })
      .catch((err) => {
        console.log("something went wrong with countries Api");
      });
  };

  getUserDetails = () => {
    api
      .get(`user/getDetails?user_id=${this.props.userId}`)
      .then((response) => {
        const details = response.data.data;
        this.setState({
          details: details,
          selectGender: details.gender,
          countrySelected: details.country,
          salutationSelected: details.salutation,
          selectWhiteList:details.isWhiteList
        });
      })
      .catch((err) => {
        console.log("something went wrong");
      });
  };

  refresh = () => {
    this.getUserDetails();
  };

  doupdateUser = (event) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var phoneno = /^\d{4,12}$/;
    if (this.refs.name.value === "") {
      this.setState({
        error: "Name is required field",
      });
    } else if (this.refs.name.value.match(/^ *$/) !== null) {
      this.setState({
        error: "Name is required field",
      });
    } else if (this.refs.email.value === "") {
      this.setState({
        error: "Email is required field",
      });
    } else if (!this.refs.email.value.match(mailformat)) {
      this.setState({
        error: "Email is Not Valid",
      });
    } else if (this.refs.phoneNumber.value === "") {
      this.setState({
        error: "Phone is Required field",
      });
    } else if (!this.refs.phoneNumber.value.match(phoneno)) {
      this.setState({
        error: "Phone No  is Not Valid",
      });
    } else if (this.state.changeselectCountry === "None") {
      this.setState({
        error: "Please select Country",
      });
    } else {
      this.setState({
        error: "",
      });
      this.updateUser(event);
    }
  };

  updateUser(event) {
    const getfailureAlert = (msg) => (
      <SweetAlert danger title={msg} onConfirm={this.hideAlertFailure}>
        Try again!
      </SweetAlert>
    );
    const getSuccessAlert = (msg) => (
      <SweetAlert
        success
        title={msg}
        onConfirm={this.hideAlertSuccess}
      ></SweetAlert>
    );

    const name = this.refs.name.value;
    const mobile = this.refs.phoneNumber.value;
    const country = this.state.changeselectCountry;
    const salutation = this.state.selectValueSalutation;
    const gender = this.state.selectGender;
    const WhiteList = this.state.selectWhiteList;

    var postData = {
      user_id: this.props.userId,
      name: name,
      mobile: mobile,
      country: country,
      salutation: salutation,
      gender: gender,
      isWhiteList:WhiteList
    };
    api
      .post("user/update", postData)
      .then((res) => {
        this.setState({
          alert: getSuccessAlert(res.data.msg),
        });
      })
      .catch((err) => {
        this.setState({
          alert: getfailureAlert(err.response.data.msg),
        });
      });
  }

  hideAlertSuccess() {
    browserHistory.push(`/Users/UserProfile/${this.props.userId}`);
  }
  hideAlertFailure() {
    this.setState({
      alert: null,
    });
    $("input").val("");
  }

  render() {
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">{this.state.error}</p>
      ) : (
        <span />
      );
    let countries = this.state.countries;
    let optionItems = countries.map((country) => (
      <option
        key={country}
        value={country}
        selected={this.state.countrySelected === country}
      >
        {country}
      </option>
    ));
    let details = this.state.details;
    let msg =
      this.state.msg !== "" ? (
        <p className={!this.state.success ? "alert success" : "alert warning"}>
          {this.state.msg}
        </p>
      ) : (
        <span></span>
      );
    return (
      <div className="create-user-wrapper">
        <div className="clearfix"></div>
        <div className="Users">
          <h2>Edit User</h2>
          {details !== null ? (
            <>
              <div className="field-block">
                <div className="field-wrapper">
                  <label htmlFor="name">Name * :</label>
                  <div className="name-block">
                    <select
                      className="form-control _prefixes valid"
                      onChange={this.callThis}
                      name="prefixes"
                      id="_prefixes"
                    >
                      <option
                        value="mr"
                        selected={this.state.salutationSelected === "mr"}
                      >
                        Mr
                      </option>
                      <option
                        value="mrs"
                        selected={this.state.salutationSelected === "mrs"}
                      >
                        Mrs
                      </option>
                      <option
                        value="ms"
                        selected={this.state.salutationSelected === "ms"}
                      >
                        Ms
                      </option>
                    </select>

                    <div className="name-width">
                      <input
                        type="text"
                        ref="name"
                        defaultValue={details.name}
                        id="name"
                        className="form-control"
                        name="name"
                        autoFocus
                      />
                    </div>
                  </div>
                </div>
                <div className="field-wrapper">
                  <label htmlFor="email">Email * :</label>
                  <input
                    type="email"
                    ref="email"
                    disabled
                    id="email"
                    defaultValue={details.email}
                    className="form-control"
                    name="email"
                  />
                </div>
              </div>
              <div className="field-block">
                <div className="field-wrapper">
                  <label htmlFor="phoneNumber">Phone Number * :</label>
                  <input
                    type="number"
                    ref="phoneNumber"
                    id="phoneNumber"
                    defaultValue={details.mobile}
                    className="form-control"
                    name="phoneNumber"
                  />
                </div>
                <div className="field-wrapper">
                  <label htmlFor="country">Country :</label>
                  <select
                    className="form-control _country valid"
                    onChange={this.callCountry}
                    name="country"
                    id="_country"
                  >
                    <option value="">None</option>
                    {optionItems}
                  </select>
                </div>
              </div>
              <div className="field-block1">
                <label htmlFor="addresss">Address:</label>
                <input
                  type="text"
                  ref="address"
                  id="addresss"
                  defaultValue={details.walletId}
                  className="form-control"
                  name="addresss"
                  disabled
                />
              </div>
              <div className="field-block">
                <div className="form-group">
                  <label className="control-label">GENDER</label>
                  <div className="inline-radio">
                    <input
                      value="male"
                      group="gender"
                      checked={this.state.selectGender === "male" ? true : false}
                      onChange={this.change.bind(this)}
                      id="male"
                      name="gender"
                      type="radio"
                    />
                    <span className="label-text">Male</span>

                    <input
                      value="female"
                      id="female"
                      checked={this.state.selectGender === "female" ? true : false}
                      onChange={this.change.bind(this)}
                      group="gender"
                      name="gender"
                      type="radio"
                    />
                    <span className="label-text">Female</span>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label">WHITELIST</label>
                  <div className="inline-radio">
                    <input
                      onChange={this.whiteList.bind(this)}
                      type="radio"
                      value={true}
                      checked={
                        this.state.selectWhiteList === true ? true : false
                      }
                    />
                    <span className="label-text">Yes</span>

                    <input
                      onChange={this.whiteList.bind(this)}
                      type="radio"
                      value={false}
                      checked={
                        this.state.selectWhiteList  === false ? true : false
                      }
                    />
                    <span className="label-text">No</span>
                  </div>
                </div>
              </div>
              {errorMgs}
              <div className="form-button">
                <button
                  type="submit"
                  onClick={this.doupdateUser.bind(this)}
                  className="btn-create"
                >
                  Update User
                </button>
                <Link to="/Users">
                  <div className="Transaction-cancel">Cancel</div>
                </Link>
              </div>
            </>
          ) : (
            <div />
          )}
          {this.state.alert}
        </div>
      </div>
    );
  }
}
export default EditUser;
