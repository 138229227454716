import axios from 'axios';
import $ from 'jquery';
import config from './config.js';
import swal from 'sweetalert';

let token = localStorage.getItem('Admin_token');
let api = {};
var BASE_URL = config.BASE_URL;

api.showLoader = () => {
  $("#loaderImage").addClass("loader-visible")
}

api.hideLoader = () => {
  $("#loaderImage").removeClass("loader-visible")
  $(".x_panel").addClass("opacity-fade");
  $(".top-buttons").addClass("opacity-fade");

}

let getDefaultHeaders = () => {
  return {
    headers: {
      'Authorization': token,
    //  "Access-Control-Allow-Origin" : "http://localhost:3000/",
    //  "Access-Control-Allow-Methods" : "GET, POST, OPTIONS",
    //  "Access-Control-Allow-Credentials":true,
    }
  }
}

api.getDefaultHeaders = getDefaultHeaders;

api.logout = () => {
  localStorage.removeItem('Admin_token');
  localStorage.removeItem('Admin_profile');
  localStorage.removeItem('admin_email');
  window.location.href = '/Login';
}

api.get = (url) => {
  api.showLoader()
  let headers = getDefaultHeaders();
  return new Promise(function (resolve, reject) {
    axios.get(`${BASE_URL}/${url}`, headers)
      .then((response) => {
        resolve(response)
        setTimeout(function () { api.hideLoader(); }, 300);
      })
      .catch((error) => {
        api.handleError(error);
      })
  });
}

api.post = (url, body) => {
  api.showLoader();
  let headers = getDefaultHeaders();
  return new Promise(function (resolve, reject) {
    axios.post(`${BASE_URL}/${url}`, body, headers)
      .then((response) => {
        resolve(response)
        api.hideLoader();
      }).catch((error) => {
        api.handleError(error);
      })
  });
}

api.delete = (url, data) => {
  let headers = getDefaultHeaders();
  headers.data = data;
  return new Promise(function (resolve, reject) {
    axios.delete(`${BASE_URL}/${url}`, headers)
      .then((response) => resolve(response))
      .catch((error) => {
        api.handleError(error);
      })
  });
}

api.handleError = (error) => {
  api.hideLoader();
  if (error && error.response) {
    if (error.response.status === 432) {
      api.showAuthFail(error.response);
    } else {
      // reject(error)
      let errorMsg = "Something went wrong, please try again!";
      if (error.response.data) {
        errorMsg = error.response.data.msg;
      }
      swal({
        title: "Error!",
        text: errorMsg,
        icon: "error"
      });
    }
  } else {
    swal({
      title: "Error!",
      text: "Something went wrong, please try again!",
      icon: "error"
    });
  }
}

api.showAuthFail = (error) => {
  if (error.data) {
    let errMsg = error.data.msg;
    if (error.data.data && error.data.data.name) {
      errMsg += " " + error.data.data.name + "!";
    }
    swal({
      title: "Authorization failed!",
      text: errMsg,
      icon: "error"
    }).then((value) => {
      api.logout();
    });
  }
}

api.LoadPopup = (e) => {
  document.getElementById(e).classList.add('show')
}

api.HidePopup = (e) => {
  document.getElementById(e).classList.remove('show')
}

export default api;