import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../Components/Loader";
import { browserHistory } from "react-router";
import Formats from "../utils/Formats";
import "./index.scss";
import { Link } from "react-router";
class TransactionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      HashId: this.props.params.HashId,
      details: [],
      statueCheck: false,
      previousStatus: "",
      nextState: "",
      alert: null,
    };
    this.hideAlertSuccess = this.hideAlertSuccess.bind(this);
    this.hideAlertFailure = this.hideAlertFailure.bind(this);
  }

  componentDidMount() {
    this.getTransactionDetails();
  }

  getTransactionDetails = () => {
    api
      .get(
        `transaction/getTransactionDetail?transactionHash=${this.state.HashId}`
      )
      .then((response) => {
        const details = response.data.data;
        this.setState({
          details: details,
          previousStatus: details.status,
          nextState: details.status,
        });
      })
      .catch((err) => {
        console.log("something went wrong");
      });
  };

  refresh = () => {
    this.getTransactionDetails();
  };

  callThis = (e) => {
    console.log(e.target.value)
    this.setState(
      {
        nextState: e.target.value,
      },
      () => {
        if (this.state.previousStatus !== this.state.nextState) {
          this.setState({
            statueCheck: true,
          });
        } else {
          this.setState({
            statueCheck: false,
          });
        }
      }
    );
  };

  updateUser(event) {
    const getfailureAlert = (msg) => (
      <SweetAlert danger title={msg} onConfirm={this.hideAlertFailure}>
        Try again!
      </SweetAlert>
    );
    const getSuccessAlert = (msg) => (
      <SweetAlert
        success
        title={msg}
        onConfirm={this.hideAlertSuccess}
      ></SweetAlert>
    );

    const transactionHash = event.target.getAttribute("data-hash");
    const status = this.state.nextState;
    const comments = this.refs.comment.value;

    var postData = {
      transactionHash: transactionHash,
      status: status,
      comments: comments,
    };

    api
      .post("transaction/updateStatus", postData)
      .then((res) => {
        this.setState({
          alert: getSuccessAlert(res.data.msg),
        });
      })
      .catch((err) => {
        this.setState({
          alert: getfailureAlert(err.response.data.msg),
        });
      });
  }

  hideAlertSuccess() {
    browserHistory.push("/Transactions");
  }
  hideAlertFailure() {
    this.setState({
      alert: null,
    });
  }

  render() {
    let details = this.state.details;
    return (
      <div className="Transaction-page">
        <Header />
        <Loader />
        <div className="wrapper">
          <div className="Transaction-details-page">
            <div className="clearfix"></div>
            <h2>Transaction Details</h2>

            <div className="Transaction-details">
              {this.state.alert}
              {details ? (
                <div className="User-profile-details">
                  <div className="User-profile-details-left">
                    <div className="field-details">
                      <label>USER ID</label>
                      <div className="id">{details.user_id}</div>
                    </div>
                    <div className="field-details">
                      <label>Status</label>
                      <div className="input-data">
                        <select
                          className="form-control _prefixes valid"
                          onChange={this.callThis}
                          name="prefixes"
                          id="_prefixes"
                        >
                          <option
                            Value="pending"
                            selected={details.status === "pending"}
                          >
                            Pending
                          </option>
                          <option
                            Value="approved"
                            selected={details.status === "approved"}
                          >
                            Approved
                          </option>
                          <option
                            Value="rejected"
                            selected={details.status === "rejected"}
                          >
                            Rejected
                          </option>
                          <option
                            Value="reversal"
                            selected={details.status === "reversal"}
                          >
                            Reversal
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="field-details">
                      <label>Amount</label>
                      <div>{details.amount}</div>
                    </div>
                    <div className="field-details">
                      <label>From</label>
                      <div>{details.fromAddress}</div>
                    </div>
                    <div className="field-details">
                      <label>Comment</label>
                      <div className="input-data">
                        <textarea
                          className="form-control edit"
                          // defaultValue={details.comments}
                          ref="comment"
                          placeholder="Write your comment here..."
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="User-profile-details-right">
                    <div className="field-details">
                      <label>To</label>
                      <div>{details.toAddress}</div>
                    </div>
                    <div className="field-details">
                      <label>Round</label>
                      <div>{details.rounds}</div>
                    </div>
                    <div className="field-details">
                      <label>Currency</label>
                      <div>{details.currency}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              )}
              <div className="form-button">
                <button
                  disabled={!this.state.statueCheck}
                  data-hash={details.transactionHash}
                  onClick={this.updateUser.bind(this)}
                  className="btn-create"
                >
                  Update
                </button>
                <Link to="/Transactions">
                  <div className="Transaction-cancel">Cancel</div>
                </Link>
              </div>
              {this.state.alert}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TransactionDetails;
