import React, { Component } from "react";
import "./App.css";
import Header from "./Components/Header";
import Loader from "./Components/Loader";
import api from "./utils/api.js";
import { Link } from "react-router";
import "./css/custom.css";

let total = 0;

class App extends Component {
  render() {
    return (
      <div className="main_container">
        <Header />
        <div className="right_col" role="main">
          <Loader />
          <div className="">
            <div className="clearfix"></div>

            <div className="row">
              <div className="equal-height">
                <InsvestmentVolume />
                <AllocationOfTokens />
              </div>
              <Users />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class InsvestmentVolume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }
  componentDidMount() {
    this.getInvestmentVolumeList();
  }

  getInvestmentVolumeList = () => {
    api
      .get("analytics/getInvestmentByCurrency")
      .then((response) => {
        const posts = response.data.data;
        this.setState({ posts });
      })
      .catch((err) => {
        console.log("something went wrong with Investment Volume :- ", err);
      });
  };
  render() {
    total = 0;
    this.state.posts.map((details) => {
      total += details.fiatInvestment;
    });

    return (
      <div className="col-md-6 col-sm-6 col-xs-12">
        <div className="x_panel fade">
          <div className="x_title">
            <h2>
              <small>Investment Volume</small>
            </h2>
            <div className="clearfix"></div>
          </div>

          <div className="x_content">
          </div>
        </div>
      </div>
    );
  }
}

class AllocationOfTokens extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }
  componentWillMount() {
    this.getTokenStatics();
  }

  getTokenStatics = () => {
    api
      .get("analytics/getTokenStatics")
      .then((response) => {
        const posts = response.data.data;
        this.setState({ posts });
      })
      .catch((err) => {
        console.log("something went wrong with Allocation Of tokens ");
      });
  };
  render() {
    return (
      <div className="col-md-6 col-sm-6 col-xs-12">
        <div className="x_panel fade">
          <div className="x_title">
            <h2>
              <small>Allocation of Tokens</small>
            </h2>
            <div className="clearfix"></div>
          </div>

          <div className="x_content">
          </div>
        </div>
      </div>
    );
  }
}
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }
  componentWillMount() {
    this.getInvestmentByCountry();
  }

  getInvestmentByCountry = () => {
    api
      .get("analytics/getInvestmentByCountry")
      .then((response) => {
        const posts = response.data.data;
        this.setState({ posts });
      })
      .catch((err) => {
        console.log("error while getInvestmentByCountry :- ", err.response);
      });
  };
  render() {
    return (
      <div className="col-md-12 col-sm-12 col-xs-12">
        <div className="x_panel fade">
          <div className="x_title">
            <h2>
              <small>Users</small>
            </h2>
            <div className="clearfix"></div>
          </div>

          <div className="x_content">
            <Link to="Home/Countries">View All</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
