import React, { Component } from "react";
import Header from "../Components/Header";
import Loader from "../Components/Loader";
import api from "../utils/api.js";
import { browserHistory } from "react-router";
import Formats from "../utils/Formats";
import moment from "moment";
import "./index.scss";
let pagination = "";

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SupportDetails: [],
      status: "",
      category: "",
      pages: 0,
      indents: [],
      currentPage: 1,
      SUPPORT_CATEGORIES: [],
      selectedCategory: "",
      SUPPORT_STATUS: [],
      selectedStatus: "",
    };
    this.pageChange = this.pageChange.bind(this);
  }

  componentDidMount() {
    this.getCategories();
    this.getStatus();
    this.getSupportDetails();
  }

  pageChange = (pageNumber) => {
    this.setState(
      {
        currentPage: pageNumber,
      },
      () => {
        this.getSupportDetails();
      }
    );
  };

  getSupportDetails = () => {
    api
      .get(
        `support/getlisting?category=${this.state.category}&status=${this.state.status}&page=${this.state.currentPage}`
      )
      .then((response) => {
        const SupportDetails = response.data.data.support;
        console.log("sd",response.data)
        const pages =
          Math.ceil(response.data.data.count / 10) > 1
            ? Math.ceil(response.data.data.count / 10)
            : 0;
        this.setState({ SupportDetails: SupportDetails, pages: pages });
      })
      .catch((err) => {
        console.log("something went wrong ");
      });
  };
  ChangeStatus = (e) => {
    if (e.target.id == "category") {
      this.setState({ category: e.target.value }, () => {
        this.getSupportDetails();
      });
    } else if (e.target.id == "supportStatus") {
      this.setState({ status: e.target.value }, () => {
        this.getSupportDetails();
      });
    }
  };

  refresh = () => {
    this.getSupportDetails();
  };

  redirect = (_id, user_id) => {
    browserHistory.push(`/Support/${_id}/${user_id}`);
  };

  getCategories = () => {
    api
      .get(`init`)
      .then((response) => {
        const SUPPORT_CATEGORIES = response.data.data.SUPPORT_CATEGORIES;
        this.setState(
          {
            SUPPORT_CATEGORIES: SUPPORT_CATEGORIES,
          },
          () => {
            this.setState({
              selectedCategory: document.getElementById("category").value,
            });
          }
        );
      })
      .catch((err) => {
        console.log("something went wrong with Init Api");
      });
  };

  getStatus = () => {
    api
      .get(`init`)
      .then((response) => {
        const SUPPORT_STATUS = response.data.data.SUPPORT_STATUS;
        this.setState(
          {
            SUPPORT_STATUS: SUPPORT_STATUS,
          },
          () => {
            this.setState({
              selectedStatus: document.getElementById("supportStatus").value,
            });
          }
        );
      })
      .catch((err) => {
        console.log("something went wrong with Init Api");
      });
  };

  render() {
    let optionCategoryItems;
    if (this.state.SUPPORT_CATEGORIES) {
      let category = this.state.SUPPORT_CATEGORIES;
      optionCategoryItems = category.map((category) => (
        <option key={category} value={category}>
          {category.toUpperCase()}
        </option>
      ));
    }
    let optionStatusItems;
    if (this.state.SUPPORT_STATUS) {
      let supportStatus = this.state.SUPPORT_STATUS;
      optionStatusItems = supportStatus.map((supportStatus) => (
        <option key={supportStatus} value={supportStatus}>
          {supportStatus.toUpperCase()}
        </option>
      ));
    }
    let details = this.state.SupportDetails;
    this.state.indents = [];
    {
      for (var i = 0; i < this.state.pages; i++) {
        this.state.indents.push(i);
      }
      this.state.pages > 1
        ? (pagination = (
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li
                  className={
                    this.state.currentPage === 1
                      ? "page-item none"
                      : "page-item"
                  }
                >
                  <a
                    className="page-link"
                    onClick={this.pageChange.bind(
                      this,
                      this.state.currentPage - 1
                    )}
                  >
                    Previous
                  </a>
                </li>

                {this.state.indents.map((item, index) => (
                  <li className="page-item">
                    <a
                      className={
                        this.state.currentPage === index + 1
                          ? "page-link active"
                          : "page-link"
                      }
                      onClick={this.pageChange.bind(this, index + 1)}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}
                <li
                  className={
                    this.state.currentPage === this.state.pages
                      ? "page-item none"
                      : "page-item"
                  }
                >
                  <a
                    className="page-link"
                    onClick={this.pageChange.bind(
                      this,
                      this.state.currentPage + 1
                    )}
                  >
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          ))
        : (pagination = <nav />);
    }
    return (
      <div className="Support-page">
        <Header />
        <Loader />
        <div className="wrapper">
          <div className="Support" role="main">
            <div className="clearfix"></div>
            <h2>Queries</h2>
            <div className="Select-options">
              <div className="select-tag">
                <label>Category:</label>
                <select
                  className="form-control"
                  id="category"
                  ref="category"
                  name="category"
                  onChange={this.ChangeStatus.bind(this)}
                >
                  <option value="">All</option>
                  {optionCategoryItems}
                </select>
              </div>
              <div className="select-tag">
                <label>Status:</label>
                <select
                  className="form-control"
                  id="supportStatus"
                  ref="supportStatus"
                  name="supportStatus"
                  onChange={this.ChangeStatus.bind(this)}
                >
                  <option value="">All</option>
                  {optionStatusItems}
                </select>
              </div>
            </div>
            <div className="Support-table-wrapper">
              <table className="Support-table">
                <thead>
                  <tr>
                    <th className="align-left">Support Id</th>
                    <th>User Id</th>
                    <th>Category</th>
                    <th>Subject</th>
                    <th>Message</th>
                    <th>Date</th>
                    <th className="align-right">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {details ? (
                    details.map((details, i) => (
                      <tr
                        key={i}
                        onClick={() =>
                          this.redirect(details._id, details.user_id)
                        }
                      >
                        <td className="id align-left">{details._id}</td>
                        <td>{details.user_id}</td>
                        <td>{details.category}</td>
                        <td>{details.subject}</td>
                        <td>{details.message}</td>
                        <td>
                          {details.update_date ? (
                            <>
                              {moment(details.update_date).format(
                                "DD MMM, YYYY"
                                )}
                            </>
                          ) : (
                            <>
                              {moment(details.create_date).format(
                                "DD MMM, YYYY"
                                )}
                            </>
                          )}
                        </td>
                        <td className={`${details.status} align-right`}>{details.status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr />
                  )}
                </tbody>
              </table>
            </div>
            <div class="align-pagination">
              {pagination}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Support;
