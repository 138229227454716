import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";

import Loader from "../Components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import { browserHistory } from "react-router";
import "./index.scss";
import moment from "moment";
import { Images } from "../assets";
import Web3provider from '../Helpers/Web3Provider';
import TierAllocationHtml from "./tier_allocationform";
import RoundSubHeader from "../Components/Round-subheader";
export default class TierAllocation extends Component
{
  constructor(props)
  {
    super(props);
    this.updateState    = this.updateState.bind(this);
    this.removeRow      = this.removeRow.bind(this);
    this.addRow         = this.addRow.bind(this);
    this.updateRowValue = this.updateRowValue.bind(this);
    this.hideAlertSuccess= this.hideAlertSuccess.bind(this);
    this.hideAlertFailure = this.hideAlertFailure.bind(this);
    //this.saveAllocation = this.saveAllocation.bind(this);
    this.onSucess       = props.onSuccess; 
    this.state = 
      {
        alert: "",
        error: "",
        RoundDetails: [],
        selectedCategory: "",
        showTable: true,
        update: "",
        tiers:[{month:"",amount:""}],
        tier_allocation:false,
        tier_id:props.tier_id,
        month:"",
        amount:"",
        distributions:[],
        showLoader:false,
      };
      this.Web3Handler = new Web3provider();

      
  }
  checkWeb3()
  {
    //this.Web3Handler.getAmount();
    //this.Web3Handler.createPreSale();
  }
  componentDidMount() {
    this.getDistributions();
  }
  getDistributions = () => {
    api
      .get(`admin/getDistributions/${this.props.tier_id}`)
      .then((response) => {
        this.setState({
          distributions: response.data.data,
        });
      })
      .catch((err) => {
        console.log("something went wrong with get-distribution Api :- ", err);
      });
  };
  
  updateState(event)
  {
      this.setState({...this.state,[event.target.name]:event.target.value});
      
  }
  saveAllocation()
  {
    const getfailureAlert = (msg) => (
      <SweetAlert danger title="Error" onConfirm={this.hideAlertFailure}>
        {msg}
      </SweetAlert>
    );
    const getSuccessAlert = (msg) => (
      <SweetAlert success title="Success!" onConfirm={this.hideAlertSuccess}>
        {msg}
      </SweetAlert>
    );



    let control = this.state;
    // console.log(this.refs);
    // if (control.tier_id === "") {
    //   this.setState({
    //     error: "Tier ID is required field",
    //   });
    // }
    // else 
    // let error = false;
    // for(let i in control.tiers)
    // {
    //   const {month,amount} = control.tiers[i];
    //   if(month=="" || amount=="")
    //   {
    //     this.setState({
    //       error: "All Fields are required and Month must be between 1-36",
    //     });
    //     error=true;
    //     break;
    //   }
    // }
    if (control.month <= 0 || control.month>36 ) {
      this.setState({
        error: "Month must be between 1-36",
      });
    }
    else if (control.amount <= 0) {
      this.setState({
        error: "Amount is required field",
      });
    }
    else
    {
      let postData = 
      {
        tier_id: control.tier_id || this.props.tier_id,
        month  :control.month, 
        amount  :control.amount,
        percentage  :control.amount,
        //allocations:control.tiers
      };
      api.showLoader();
      this.setState({...this.state,showLoader:true});
      this.Web3Handler.createTierAlloc(postData).then(res=>{
        // this.setState({
        //   alert: getSuccessAlert("Allocation Done Successfully"),
        // })
        postData.sTransactionHash = res.blockHash;
        return postData;
      })
      .then(pData=>{

        api
        .post("admin/distributions",pData)
        .then((res) => {
          this.setState({...this.state,showLoader:false});
          this.setState({
            alert: getSuccessAlert(res.data.msg),
          });
        })
      })
      .catch(err=>{
        api.hideLoader();
        this.setState({
          showLoader:false,
          alert: getfailureAlert(err.message),
        });
       
      });

      // api
      //   .post("admin/distributions",postData)
      //   .then((res) => {
      //     this.setState({
      //       alert: getSuccessAlert(res.data.msg),
      //     });
      //   })
      //   .catch((err) => {
      //     this.setState({
      //       alert: getfailureAlert(err.response.data.msg),
      //     });
      //   });

    }

   


  }

  addRow()
  {
  this.setState(state=>state.tiers.push({month:"",amount:""}))
  }
  removeRow(index)
  {
    this.setState(state=>state.tiers.splice(index,1));
  }
  updateRowValue(event,i)
  {
    let {name,value} = event.target;
    this.setState(state=>state.tiers[i][name]=value)
  }
  hideAlertSuccess() {
    api.hideLoader();
    this.setState({
      alert: null,
      showLoader:false,
    });
    this.refs.amount.value ="";
    this.refs.month.value  ="";
    this.getDistributions();
  }
  hideAlertFailure() {
    
    this.setState({
      alert: null,
      showLoader:false
    });
    this.refs.amount.value ="";
    this.refs.month.value  ="";
  }

  render()
  {

    let distributions = this.state.distributions;
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">
          <i className="fa fa-info-circle"></i> {this.state.error}
        </p>
      ) : (
        <span />
      );
      
    return (
      <div className="Round-page">
       <Loader />
        <Header />
        
        <div className="wrapper">
          <div className="Round">
            <h2>Tier Allocation for `{ this.props.tier_id}`</h2>
            {this.state.alert}
            {/* <p className="message">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p> */}
            
              <>
                <div className="Round-form">
                  <div className="field-block">
                   {/* <div className="field-wrapper">
                      <label>Tier ID</label>
                      <input
                        className="form-elements"
                        name="tier_id"
                        type="hidden"
                        onChange={this.updateState}
                        defaultValue={this.state.tier_id}
                       />
          </div>*/}
                    
                    <div className="field-wrapper">
                      <label>Month</label>
                      <input
                        className="form-elements"
                        name="month"
                        ref="month"
                        max="36"
                        min="1"
                        type="number"
                        onChange={this.updateState}
                        
                      />
                    </div>
                    <div className="field-wrapper">
                      <label>Amount (in Percentage) </label>
                      <input
                        className="form-elements"
                        name="amount"
                        ref="amount"
                        type="number"
                        onChange={this.updateState}
                        
                      />
                    </div>
                    <div className="field-wrapper">&nbsp;</div>
                    
                  </div>
                  {/*this.state.tiers.map((v,i)=>{
                        return(<TierAllocationHtml {...v} deleteAllocationHtml={this.removeRow} updateOnChange={this.updateRowValue} tier_id={this.state.tier_id} index={i} />)
                    })
                  <button className="btn btn-link" onClick={this.addRow} >Add More[+]</button>*/}
                 <br />
                  
                  {errorMgs}
                  <div className="form-button">
                    <button
                      className="btn-save"
                      id="_profile_update"
                      onClick={this.saveAllocation.bind(this)}
                      name="profileupdate"
                      disabled={this.state.showLoader}
                    >
                      Save
                    </button>
                    <button
                      className="btn-cancel"
                      id="_profile_update"
                      onClick={this.hideAlertFailure}
                      name="profileupdate"
                      disabled={this.state.showLoader}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn-cancel"
                      id="_profile_update"
                      onClick={this.onSucess}
                      name="profileupdate"
                      disabled={this.state.showLoader}
                    >
                      Back
                    </button>
                   
                  </div>
                </div>
                <br />
              
                <div className="round-table-wrapper">
                  <h2>Distibutions Details</h2>
                  <table className="round-table">
                    <thead>
                      <tr>
                        {/*<th className="align-left">Tier ID</th>*/}
                        <th>Month</th>
                        <th>Amount</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {distributions.length > 0 ? (
                        distributions.map((list, i) => (
                          <tr>
                            {/*<td className="align-left">{list._id}</td>*/}
                            <td data-label="Date">
                              <span className="Month uppercase">
                                {list.month}
                              </span>
                            </td>
                            <td data-label="Date">
                              <span className="Month uppercase">
                                {list.percentage}
                              </span>
                            </td>
                           {/* <td className="action-btns">
                            <img
                                src={Images.EditIcon}
                                alt={""}
                                onClick={() => this.TierAllocation(list)}
                              />
                              &nbsp; &nbsp;
                              <img
                                src={Images.EditIcon}
                                alt={""}
                                onClick={() => this.EditRound(list)}
                              />
                              &nbsp; &nbsp;
                              <img
                                src={Images.DeleteIcon}
                                alt={""}
                                onClick={() => this.DeleteRound(list._id)}
                              />
                            </td>*/}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="align-center">
                            No Distibutions Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                      
              </>
            
          </div>
        </div>
      </div>
    );
  }
}