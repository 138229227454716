import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";
import { Link } from "react-router";
import Loader from "../Components/Loader";
import "./index.scss";
import { Images } from "../assets";
import { CSVLink } from "react-csv";
let pagination = "";

class Users extends Component {
  componentDidMount() {
    this.getUserList();
  }

  getUserList = () => {
    api
      .get(`user/getUsers?page=${this.state.currentPage}`)
      .then((response) => {
        const posts = response.data.data.data;
        const pages =
          Math.ceil(response.data.data.totalCount / 10) > 1
            ? Math.ceil(response.data.data.totalCount / 10)
            : 0;
        this.setState({ posts: posts, pages: pages });
      })
      .catch((err) => {
        console.log("something went wrong");
      });
  };
  pageChange = (pageNumber) => {
    this.setState(
      {
        currentPage: pageNumber,
      },
      () => {
        this.getUserList();
      }
    );
  };
  refresh = () => {
    this.getUserList();
  };

  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      pages: 0,
      indents: [],
      currentPage: 1,
      data: []
    };
    this.pageChange = this.pageChange.bind(this);
    this.csvLinkEl = React.createRef();
  }

  sendEvent = async() => {
    await api
      .get(
        `user/list`
      )
      .then((response) => {
        let result = response.data.data
        this.setState({ data: result}, () => {
          setTimeout(() => {
            this.csvLinkEl.current.link.click();
          });
        });
      })
      .catch((err) => {
        console.log("something went wrong");
      });
  };

  download=async()=>{
    const data = await this.sendEvent();
  }

  render() {
    const { data } = this.state;
    this.state.indents = [];
    {
      for (var i = 0; i < this.state.pages; i++) {
        this.state.indents.push(i);
      }

      this.state.pages > 1
        ? (pagination = (
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li
                  className={
                    this.state.currentPage === 1
                      ? "page-item none"
                      : "page-item"
                  }
                >
                  <a
                    className="page-link"
                    onClick={this.pageChange.bind(
                      this,
                      this.state.currentPage - 1
                    )}
                  >
                    Previous
                  </a>
                </li>

                {this.state.indents.map((item, index) => (
                  <li className="page-item">
                    <a
                      className={
                        this.state.currentPage === index + 1
                          ? "page-link active"
                          : "page-link"
                      }
                      onClick={this.pageChange.bind(this, index + 1)}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}
                <li
                  className={
                    this.state.currentPage === this.state.pages
                      ? "page-item none"
                      : "page-item"
                  }
                >
                  <a
                    className="page-link"
                    onClick={this.pageChange.bind(
                      this,
                      this.state.currentPage + 1
                    )}
                  >
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          ))
        : (pagination = <nav />);
    }
    return (
      <div className="Users-page">
        <Header />
        <Loader />
        <div className="wrapper">
          <div className="clearfix"></div>
          <div className="Users-head">
            <h2>Users</h2>
            <div className="Users-head-right">
              <Link to="/Users/CreateUser" className="create">
                {/* <img src={Images.ADD_ICON} alt={""} /> */}
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;Create User
              </Link>
            </div>
          </div>
          <div className="Users">
            <div className="table-wrapper">
              <div class="head">
                <h2>Details</h2>
                <div className="btn download"
                  onClick={() => this.download()}
                  >
                  <i className='fa fa-download'></i> &nbsp;User List
                </div>
              </div>
            <CSVLink
              filename="data.csv"
              data={data}
              ref={this.csvLinkEl}
            />
              <table className="Users-table">
                <thead>
                  <tr>
                    <th className="align-left" scope="col">
                      Id
                    </th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Country</th>
                    <th scope="col">Purchased</th>
                    <th scope="col">WHITELISTED</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.posts.map((details, i) => (
                    <TableRow
                      key={i}
                      myprop={details}
                      refreshList={this.refresh}
                    />
                  ))}
                </tbody>
              </table>
              <div className="align-pagination">
              {pagination}

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class TableRow extends Component {
  constructor(props) {
    super(props);
    };
  change = (e) => {
    var postData = {
      user_id: this.props.myprop.user_id,
      isWhiteList: e.target.value,
    };
    api
      .post(`admin/userWhiteList`,postData)
      .then((response) => {
        this.props.refreshList()
      })
      .catch((err) => {
        console.log("something went wrong");
      });
  };
  render() {
    let details = this.props.myprop;
    let purchasedAmt = 0,
      receivedAmt = 0,
      withdrawnAmt = 0,
      bonusAmt = 0;
    details.transaction.map((transaction) => {
      if (transaction.type === "purchased") {
        purchasedAmt = transaction.filatValue;
      } else if (transaction.type === "received") {
        receivedAmt = transaction.filatValue;
      } else if (transaction.type === "transferred") {
        withdrawnAmt = transaction.filatValue;
      } else if (transaction.type === "bonus") {
        bonusAmt = transaction.token;
      }
    });
    return (
      <tr>
        <td className="userId align-left" data-label="Id">
          <Link
            to={"/Users/UserProfile/" + details.user_id}
            params={{ userId: details.user_id }}
          >
            {details.user_id}
          </Link>
        </td>

        <td data-label="Email">
          {details.email}&nbsp;&nbsp;
          {details.isEmailVerified ? (
            <img src={Images.VERIFIED} alt={""} />
          ) : (
            <img src={Images.UNAPPROVED} alt={""} />
          )}
        </td>
        <td data-label="Phone">{details.mobile}</td>
        <td data-label="Country" className="cpt">
          {details.country}
        </td>
        <td data-label="Purchased">{purchasedAmt}</td>
        <td data-label="WHITELISTED">
          <div className="form-group">
            <div className="inline-radio">
              <input
                onChange={this.change.bind(this)}
                type="radio"
                value={true}
                checked={details.isWhiteList === true ? true : false}
              />
              <span className="label-text">Yes</span>

              <input
                onChange={this.change.bind(this)}
                type="radio"
                value={false}
                checked={details.isWhiteList === false ? true : false}
              />
              <span className="label-text">No</span>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default Users;
