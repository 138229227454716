import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";
import Loader from "../Components/Loader";
import HomeHeader from "../Components/Home-header";

class Countries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }
  componentDidMount() {
    this.getInvestmentByCountry();
  }

  getInvestmentByCountry = () => {
    api
      .get("analytics/getInvestmentByCountry")
      .then((response) => {
        const posts = response.data.data;
        this.setState({ posts });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  render() {
    let totalAmount = 0,
    totalRegisteredUser=0,
    totalDispositCount=0,
    totalAverageInvestment=0,
    totalShare=0;
    this.state.posts.map((details) => {
      totalAmount += details.amount;
      totalRegisteredUser += details.registered_users.count;
      totalDispositCount += details.transaction_count;
      totalAverageInvestment += details.amount/details.registered_users.count;
      totalShare += details.share_percent
    });
    return (
      <div className="Home-Page">
        <Header />
        <HomeHeader />
        <Loader />
        <div className="wrapper">
          <div className="clearfix"></div>

          <div className="Home">
            <div className="table-wrapper">
              <h2>Users</h2>
              <table className="Users-table">
                <thead>
                  <tr>
                    <th className="align-left">Country</th>
                    <th>Investments(USD)</th>
                    <th>Registered Users</th>
                    <th>Deposit Count</th>
                    <th>Average Investment(USD)</th>
                    <th className="align-right">Share (%)</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.posts ? (
                    this.state.posts.map((details, i) => (
                      <tr key={i}>
                        <td className="cpt align-left">{details.country}</td>
                        <td>$ {parseFloat(details.amount).toFixed(2)}</td>
                        <td>{details.registered_users.count}</td>
                        <td>{details.transaction_count}</td>
                        <td>
                          ${" "}
                          {parseFloat(details.amount /
                            details.registered_users.count).toFixed(2)}
                        </td>
                        <td className="align-right">{details.share_percent}</td>
                      </tr>
                    ))
                  ) : (
                    <tr />
                  )}
                </tbody>
                <br />
                <tfoot>
                  <tr>
                    <td className="bold align-left">Total</td>
                    <td>{parseFloat(totalAmount).toFixed(2)}</td>
                    <td>{totalRegisteredUser}</td>
                    <td>{totalDispositCount}</td>
                    <td>{parseFloat(totalAverageInvestment).toFixed(2)}</td>
                    <td className="bold align-right">{totalShare}%</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Countries;
