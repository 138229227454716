import React,{useState} from "react";
import { Link } from "react-router";
import "./index.scss";
import Swal from 'sweetalert2';
import api from "../../utils/api";

function HomeHeader() {

  const TokenDistribution= async() => {
    console.log("Token distribution")
    Swal.fire({
      title: '<h3>Are you sure you want to start Token Distribution?</h3>',
      text:"Enter 'Token Distribution' in below input box to confirm",
      input: 'text',
      width:"40rem",      
      padding:"2rem 3rem",
      confirmButtonColor: '#bd712e',
      showCancelButton: true,
      cancelButtonColor:'#dadce0',
      cancelButtonTextColor:'#6e6f76',
      // customClass: {
      //   validationMessage: 'my-validation-message'
      // },
      customClass: {
        validationMessage: 'my-validation-message'
      },
      preConfirm: (value) => {
        if (value !== 'Token Distribution') {
          Swal.showValidationMessage(
            'Enter correct statement'
          )
        }else{
          ConfirmTokenDistribution();
        }
      }
    })
  };

  const ConfirmTokenDistribution = ()=>{   
    api
      .get("analytics/distribution")
      .then((response) => {
        const result= response;
        Swal.fire({
          title: `${response.data.msg}`,
        })
        console.log("Token Distribution Started")
      })
      .catch((err) => {
        console.log("Something Went Wrong With Token Distribution :- ", err);
      });
  }
  const Refund= async() => {
    console.log("Refund Amount")
    Swal.fire({
      title: '<h3>Are you sure you want to start Refund process ?</h3>',
      text:"Enter 'Refund Amount' in below input box to confirm",
      input: 'text',
      width:"40rem",      
      padding:"2rem 3rem",
      confirmButtonColor: '#bd712e',
      showCancelButton: true,
      cancelButtonColor:'#dadce0',
      cancelButtonTextColor:'#6e6f76',
      // customClass: {
      //   validationMessage: 'my-validation-message'
      // },
      customClass: {
        validationMessage: 'my-validation-message'
      },
      preConfirm: (value) => {
        if (value !== 'Refund Amount') {
          Swal.showValidationMessage(
            'Enter correct statement'
          )
        }else{
          ConfirmRefund();
        }
      }
    })
  };

  const ConfirmRefund = ()=>{   
    api
      .get("analytics/refund-amount")
      .then((response) => {
        const result= response;
        Swal.fire({
          title: `${response.data.msg}`,
        })
        console.log("Refund Process Started")
      })
      .catch((err) => {
        console.log("Something Went Wrong With Refund Process :- ", err);
      });
  }
  return (
    <div className="home-header">
      <div className="home-header-wrapper">
        <Link to={"/"} className="header-item" activeClassName="activeRoute">
          Token Sale
        </Link>
        <Link
          to={"/Home/Countries"}
          className="header-item"
          activeClassName="activeRoute"
        >
          Users
        </Link>
        <Link
          to={"/Home/DailyInvestments"}
          className="header-item"
          activeClassName="activeRoute"
        >
          Daily Investment
        </Link>
       {/* 
        <buttton className="header-item distribution-btn" onClick={() => TokenDistribution()}>
          Distribution
        </buttton>
        <buttton className="header-item distribution-btn refund" onClick={() => Refund()}>
          Refund
        </buttton>
        */}
      </div>
    </div>
  );
}
export default HomeHeader;
