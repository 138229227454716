import React, { Component } from "react";
import Header from "../Components/Header";
import { Link } from "react-router";
import api from "../utils/api.js";
import Loader from "../Components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import $ from "jquery";
import moment from "moment";
import Formats from "../utils/Formats";
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: null,
      alert: null,
      transactions: [],
    };
    this.hideAlert = this.hideAlert.bind(this);
  }

  componentDidMount() {
    this.getUserDetails();
    this.getUserTransactions();
  }

  getUserDetails = () => {
    api
      .get(`user/getDetails?user_id=${this.props.params.userId}`)
      .then((response) => {
        const details = response.data.data;
        this.setState({ details });
      })
      .catch((err) => {
        console.log("something went wrong");
      });
  };
  getUserTransactions = () => {
    api
      .get(`transaction/getTransactions?user_id=${this.props.params.userId}`)
      .then((response) => {
        const details = response.data.data.result;
        this.setState({ transactions:details });
      })
      .catch((err) => {
        console.log("something went wrong");
      });
  };

  refresh = () => {
    this.getUserDetails();
  };
  refreshUserTransactions = () => {
    this.getUserTransactions();
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
    $("input").val("");
  }

  render() {
    return (
      <div className="Users-page">
        <Header />
        <Loader />
        <div className="wrapper">
          <div className="clearfix"></div>
          <div className="Users">
            <Details
              myDetails={this.state.details}
              userId={this.props.params.userId}
              refresh={this.refresh.bind(this)}
              refreshUserTransactions = {this.refreshUserTransactions.bind(this)}
            />
            {this.state.alert}
            <Transactions
              myDetails={this.state.details}
              transactions={this.state.transactions}
            />
          </div>
        </div>
      </div>
    );
  }
}

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myId: this.props.myId,
      details: this.props.myDetails,
      toggle: false,
      alert: null,
      error: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
  }

  doupdateUserPassword = (event) => {
    if (this.refs.password.value === "") {
      this.setState({
        error: "Password is required field",
      });
    } else if (this.refs.password.value.length < 8) {
      this.setState({
        error: "Password should be min 8 characters long",
      });
    } else if (this.refs.password.value !== this.refs.confirmPassword.value) {
      this.setState({
        error: "Confirm Password should Match with Password",
      });
    } else {
      this.setState({
        error: "",
      });
      this.updateUserPassword(event);
    }
  };

  updateUserPassword(e) {
    var x = e.target.getAttribute("data-id");
    const password = this.refs.password.value;
    let data = {
      user_id: x,
      password: password,
    };

    const getfailureAlert = () => (
      <SweetAlert
        danger
        title="Password Udated unsuccessful!"
        onConfirm={this.hideAlert}
      >
        Try again!
      </SweetAlert>
    );

    const getSuccessAlert = () => (
      <SweetAlert
        success
        title="Password Udated Successfully!"
        onConfirm={this.hideAlert}
      ></SweetAlert>
    );

    api
      .post("user/updatePassword", data)
      .then((res) => {
        this.setState({
          alert: getSuccessAlert(),
        });
        this.refs.password.value = "";
        this.refs.confirmPassword.value = "";
      })
      .catch((err) => {
        this.setState({
          alert: getfailureAlert(),
        });
      });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
    $(".popup").removeClass("show");
  }

  edittoggle() {
    this.setState({ toggle: !this.state.toggle });
  }

  lock = () => {
    var x = this.props.userId;
    let data = {
      user_id: x,
    };
    const getfailureAlert = () => (
      <SweetAlert
        danger
        title="User Locked Unsuccessfull"
        onConfirm={this.hideAlert}
      >
        Try again!
      </SweetAlert>
    );
    const getSuccessAlert = () => (
      <SweetAlert
        success
        title="User has been Locked"
        onConfirm={this.hideAlert}
      ></SweetAlert>
    );
    api
      .post("user/lock", data)
      .then((res) => {
        this.setState({
          alert: getSuccessAlert(),
        });
        this.props.refresh();
      })
      .catch((err) => {
        this.setState({
          alert: getfailureAlert(),
        });
        this.props.refresh();
      });
  };

  unlock = () => {
    var x = this.props.userId;
    let data = {
      user_id: x,
    };
    const getfailureAlert = () => (
      <SweetAlert
        danger
        title="user unlock not successfull"
        onConfirm={this.hideAlert}
      >
        Try again!
      </SweetAlert>
    );
    const getSuccessAlert = () => (
      <SweetAlert
        success
        title="User has been unlocked"
        onConfirm={this.hideAlert}
      ></SweetAlert>
    );
    api
      .post("user/unlock", data)
      .then((res) => {
        this.setState({
          alert: getSuccessAlert(),
        });
        this.props.refresh();
      })
      .catch((err) => {
        this.setState({
          alert: getfailureAlert(),
        });
        this.props.refresh();
      });
  };

  HidePopup = (e) => {
    document.getElementById(e).classList.remove('show')
    this.refs.password.value = "";
    this.refs.confirmPassword.value = "";
  }

  render() {
    let details = this.props.myDetails;
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">{this.state.error}</p>
      ) : (
        <span />
      );
    return (
      <>
        <div className="user-profile-head">
          <h2>User Profile</h2>
          <div class="btn-group">
            <button type="button" class="btn btn-lg split-padding">
              <Link
                to={"/Users/EditUser/" + this.props.userId}
                params={{ userId: this.props.userId }}
                className="edituser-btn"
              >
                Edit User
              </Link>
            </button>
            <button
              type="button"
              class="btn btn-lg dropdown-toggle dropdown-toggle-split split-padding"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="visually-hidden">
                <i class="fa fa-chevron-down" />
              </span>
            </button>
            <ul class="dropdown-menu">
              <div
                className="toggle-item"
                onClick={api.LoadPopup.bind(this, "resetPassword")}
              >
                Reset Password
              </div>
              <div
                className="toggle-item"
                onClick={api.LoadPopup.bind(this, "addToken")}
              >
                Add Token
              </div>

              <Link to={"/Users/CreateTransaction/" + this.props.userId}>
                <div className="toggle-item">Add Transaction</div>
              </Link>
              <div className="toggle-item">
                {!details?.locked ? (
                  <div onClick={this.lock.bind(this)}>Lock User</div>
                ) : (
                  <div onClick={this.unlock.bind(this)}>Unlock User</div>
                )}
              </div>
            </ul>
          </div>
        </div>
        <div className="table-wrapper">
          <div className="clearfix"></div>
          {details !== null ? (
            <div>
              <div className="User-profile-details">
                <div className="User-profile-details-left">
                  <div className="field-details">
                    <label>ID</label>
                    <div>{details.user_id}</div>
                  </div>
                  <div className="field-details">
                    <label>NAME</label>
                    <div>{details.name}</div>
                  </div>
                  <div className="field-details">
                    <label>EMAIL</label>
                    <div>{details.email}</div>
                  </div>
                  <div className="field-details">
                    <label>PHONE</label>
                    <div>{details.mobile}</div>
                  </div>
                  <div className="field-details">
                    <label>COUNTRY</label>
                    <div>{details.country}</div>
                  </div>
                </div>
                <div className="User-profile-details-right">
                  <div className="field-details">
                    <label>GENDER</label>
                    <div>{details.gender}</div>
                  </div>
                  <div className="field-details">
                    <label>D.O.B</label>
                    <div>{details.dob}</div>
                  </div>
                  <div className="field-details">
                    <label>CREATED AT</label>
                    <div>
                      <>{moment(details.create_date).format("DD MMM, YYYY")}</>
                    </div>
                  </div>
                  <div className="field-details">
                    <label>WALLET ADDRESS</label>
                    <div>{details.walletId}</div>
                  </div>
                </div>
              </div>
              <div id="resetPassword" className="popup fade" role="dialog">
                <div className="ico-dialog">
                  <div className="ico-content">
                    <div className="profilePopup-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="ico"
                        onClick={this.HidePopup.bind(this, "resetPassword")}
                      >
                        &times;
                      </button>
                      <h4 className="profilePopup-title">Reset Password</h4>
                    </div>

                    <div className="profilePopup-body">
                      <label htmlFor="password">Password * :</label>
                      <input
                        type="password"
                        ref="password"
                        className="form-control"
                      />
                      <br />
                      <label htmlFor="confirmPassword">
                        Confirm Password * :
                      </label>
                      <input
                        type="password"
                        ref="confirmPassword"
                        className="form-control"
                      />
                      <br />
                      {errorMgs}
                    </div>

                    <div className="profilePopup-footer">
                      <button
                        type="button"
                        data-id={details._id}
                        className="add-btn"
                        onClick={this.doupdateUserPassword.bind(this)}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.alert}
              <AddTokens myId={details._id} refreshUserTransactions={this.props.refreshUserTransactions.bind(this)}/>
            </div>
          ) : (
            <div />
          )}
        </div>
      </>
    );
  }
}

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: this.props.myDetails,
      transactions: this.props.transactions,
    };
  }
  render() {
    let details = this.props.myDetails;

    return (
      <div className="table-wrapper">
        <h2>Transactions</h2>
        <div className="clearfix"></div>
        <table className="user-transaction-table">
          <thead>
            <tr>
              <th className="align-left">Amount</th>
              <th>Date</th>
              {/* <th>Type</th> */}
              <th>ROUND</th>
              <th className="comments">Comments</th>
              <th>Hash</th>
              <th className="align-right">Status</th>
            </tr>
          </thead>
          <tbody>
            {details !== null ? (
              this.props.transactions.map((trans, i) => (
                <TableRows key={i} mytrans={trans} refreshList={this.refresh} />
              ))
            ) : (
              <tr />
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

class TableRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: this.props.mytrans,
    };
  }
  render() {
    let details = this.props.mytrans;
    return (
      <tr>
        <td className="align-left">{parseFloat(details.amount).toFixed(2)}</td>
        <td>
          {details.update_date
            ?  <>{moment(details.update_date).format("DD MMM, YYYY")}</>
            :  <>{moment(details.create_date).format("DD MMM, YYYY")}</>}
        </td>
        {/* <td>{details.type}</td> */}
        <td style={{textTransform:'capitalize'}}>{details.rounds}</td>
        <td className="comments">{details.comments ? details.comments : ""}</td>
        <td className="hash">{details.transactionHash}</td>
        <td className={`${details.status} align-right`}>{details.status}</td>
      </tr>
    );
  }
}

class AddTokens extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.myId,
      alert: null,
      error: "",
      selectedCurrency: "BUSD",
      currencies: [],
      Rounds:[],
      selectedRound:'pre-seed',
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideSuccessAlert = this.hideSuccessAlert.bind(this);
  }

  componentDidMount() {
    this.getCurrencies();
  }

  floatingPoint = (e) => {
    var integerFloat = /^[-+]?[0-9]+\.[0-9]+$/;
    if (!e.target.value.match(integerFloat)) {
      this.setState({
        error: "Input should be Integer or Float ",
      });
    } else {
      this.setState({
        error: "",
      });
    }
  };

  ChangeRound = (e) => {
    this.setState({ selectedRound: e.target.value }, () => {});
  };

  doAddToken = (event) => {
    var integerFloat = /^(0|[1-9]\d*)(\.\d+)?$/;
    var numbers = /^[0-9]+$/;
    if (this.refs.TokenAmount.value === "") {
      this.setState({
        error: "Token Amount is required field",
      });
    }

    else if (
      !this.refs.TokenAmount.value.match(integerFloat)
    ) {
      this.setState({
        error: "Input should be Integer or Floating ",
      });
    } else if (this.refs.comments.value === "") {
      this.setState({
        error: "Comments is required field",
      });
    } else {
      this.setState({
        error: "",
      });
      this.AddToken(event);
    }
  };

  AddToken(e) {
    var x = e.target.getAttribute("data-id");
    const token_amount = this.refs.TokenAmount.value;
    const comments = this.refs.comments.value;
    const round = this.state.selectedRound;
    let data = {
      user_id: x,
      token: token_amount,
      comments: comments,
      rounds:round
    };

    const getfailureAlert = () => (
      <SweetAlert danger title="Token not Added !" onConfirm={this.hideAlert}>
        Try again!
      </SweetAlert>
    );

    const getSuccessAlert = () => (
      <SweetAlert
        success
        title="Token  added Successfully!"
        onConfirm={this.hideSuccessAlert}
      ></SweetAlert>
    );

    api
      .post("transaction/sendtoken", data)
      .then((res) => {
        this.setState({
          alert: getSuccessAlert(),
        });
        this.refs.TokenAmount.value = "";
        this.refs.comments.value = "";
      })
      .catch((err) => {
        this.setState({
          alert: getfailureAlert(),
        });
      });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
    $(".popup").removeClass("show");
  }
  hideSuccessAlert() {
    this.props.refreshUserTransactions();
    this.setState({
      alert: null,
    });
    $(".popup").removeClass("show");
  }


  getCurrencies = () => {
    api
      .get("init")
      .then((response) => {
        let currencies = response.data.data.SUPPORTED_COINS;
        let rounds =  response.data.data.ROUNDS;
        this.setState({
          currencies: currencies,
          Rounds:rounds
        });
      })
      .catch((err) => {
        console.log("something went wrong with countries Api");
      });
  };
  ChangeCurrency = (e) => {
    this.setState({ selectedCurrency: e.target.value }, () => {});
  };

  render() {
    let optionItems;
    // let roundItems;
    if (this.state.currencies) {
      let currencies = this.state.currencies;
      optionItems = this.state.currencies ? (
        currencies.map((currency) => (
          <option key={currency} value={currency}>
            {currency}
          </option>
        ))
      ) : (
        <options />
      );
    } else {
      optionItems = [];
    }
    // if (this.state.Rounds) {
    //   let rounds = this.state.Rounds;
    //   roundItems = this.state.Rounds ? (
    //     rounds.map((round) => (
    //       <option key={round} value={round}>
    //         {round}
    //       </option>
    //     ))
    //   ) : (
    //     <options />
    //   );
    // } else {
    //   roundItems = [];
    // }
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">{this.state.error}</p>
      ) : (
        <span />
      );
    return (
      <div>
        {this.state.alert}
        <div id="addToken" className="popup fade" role="dialog">
          <div className="ico-dialog">
            <div className="ico-content">
              <div className="profilePopup-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={api.HidePopup.bind(this, "addToken")}
                >
                  &times;
                </button>
                <h4 className="profilePopup-title">Add Token</h4>
              </div>

              <div className="profilePopup-body">
                <label Htmlfor="TokenAmount">TOKEN AMOUNT:</label>
                <input
                  type="text"
                  ref="TokenAmount"
                  id="TokenAmount"
                  className="form-control"
                  name="TokenAmount"
                />
                <br />
              <label htmlFor="currency">ROUND*:</label>
              <select
                className="form-control"
                onChange={this.ChangeRound.bind(this)}
              >
            <option value="-1" disabled>Select Round</option>
            <option key={'pre-seed'} value={'pre-seed'}>{`Pre-Seed`}</option>
            <option key={'seed'} value={'seed'}>{`Seed`}</option>
            <option key={'pre-sale-whitelist'} value={'pre-sale-whitelist'}>{`Pre-Sale-Whitelist`}</option>
            <option key={'pre-sale-public'} value={'pre-sale-public'}>{`Pre-Sale-Public`}</option>
            <option key={'public-sale'} value={'public-sale'}>{'Public-Sale'}</option>
            <option key={'strategic-partnerships'} value={'strategic-partnerships'}>{'Strategic-Partnerships'}</option>
            <option key={'team'} value={'team'}>{'Team'}</option>
            <option key={'advisors'} value={'advisors'}>{'Advisors'}</option>
            <option key={'treasury'} value={'treasury'}>{'Treasury'}</option>
                {/* {this.state.Rounds ? roundItems : <options />} */}
              </select><br/>
                <label Htmlfor="comments">Comments:</label>
                <textarea
                  ref="comments"
                  id="comments"
                  className="form-control"
                  name="comments"
                ></textarea>
                <br />
                {errorMgs}
              </div>

              <div className="profilePopup-footer">
                <button
                  type="button"
                  data-id={this.state.id}
                  onClick={this.doAddToken.bind(this)}
                  className="add-btn"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfile;
