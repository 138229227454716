
let Formats = {};
Formats.DateFormat = (dates) => {
    if (dates) {
        var date = new Date(dates);
        var month_names = ["Jan", "Feb", "Mar",
            "Apr", "May", "Jun",
            "Jul", "Aug", "Sep",
            "Oct", "Nov", "Dec"];
        var month = month_names[date.getMonth()];
        var day = date.getDate();
        var year = date.getFullYear();
        var mins = date.getMinutes();
        var hrs = date.getHours();
        var formated = day + " " + month + " " + year + " - " + hrs + ":" + mins;
        return formated;
    } else {
        return "";
    }
}

export default Formats;