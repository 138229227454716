import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";
import SweetAlert from "react-bootstrap-sweetalert";
import "jquery-validation";
import Loader from "../Components/Loader";
import $ from "jquery";
import { browserHistory } from "react-router";
import { Link } from "react-router";
import config from './../utils/config.js';
import "./index.scss";

class Withdraws extends Component {
  render() {
    return (
      <div className="Transaction-page">
        <Header />
        <Loader />
        <TransactionsWithdraw myID={this.props.params.userId} />
      </div>
    );
  }
}

class TransactionsWithdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      alert: null,
      status: "pending",
      currencies: [],
      Rounds:[],
      selectedRound:'pre-sale-whitelist',
      selectedCurrency: "BUSD",
      error: "",
    };
    this.hideAlertSuccess = this.hideAlertSuccess.bind(this);
    this.hideAlertFailure = this.hideAlertFailure.bind(this);
  }
  getCurrencies = () => {
    api
      .get("init")
      .then((response) => {
        let currencies = response.data.data.SUPPORTED_COINS;
        let rounds =  response.data.data.ROUNDS;
        this.setState({
          currencies: currencies,
          Rounds:rounds
        });
      })
      .catch((err) => {
        console.log("something went wrong with countries Api");
      });
  };
  componentDidMount() {
    this.getCurrencies();
    this.props.myID && this.getUserDetails();
  }
  floatingPoint = (e) => {
    var integerFloat = /^(0|[1-9]\d*)(\.\d+)?$/;
    if (!e.target.value.match(integerFloat)) {
      this.setState({
        error: "Input should be Integer or Float ",
      });
    } else {
      this.setState({
        error: "",
      });
    }
  };

  doAddWithdraw = (event) => {
    var integerFloat = /^(0|[1-9]\d*)(\.\d+)?$/;
    if (this.refs.user.value === "") {
      this.setState({
        error: "User Id is required field",
      });
    } else if (this.refs.fromAddress.value === "") {
      this.setState({
        error: "From Address is required field",
      });
    } else if (this.refs.toAddress.value === "") {
      this.setState({
        error: "To Address is Required field",
      });
    } else if (this.refs.transactionHash.value === "") {
      this.setState({
        error: "Transaction Hash is Required field",
      });
    } else if (this.refs.amount.value === "") {
      this.setState({
        error: "Amount is Required field",
      });
    } else if (  !this.refs.amount.value.match(integerFloat)) {
      this.setState({
        error: "Input should be Integer or Floating ",
      });
    } else if (this.refs.comments.value === "") {
      this.setState({
        error: "Comments is Required field",
      });
    } else {
      this.setState({
        error: "",
      });
      this.AddWithdraw(event);
    }
  };

  AddWithdraw(e) {
    const getfailureAlert = (msg) => (
      <SweetAlert danger title={msg} onConfirm={this.hideAlertFailure}>
        Try again!
      </SweetAlert>
    );
    const getSuccessAlert = (msg) => (
      <SweetAlert
        success
        title={msg}
        onConfirm={this.hideAlertSuccess}
      ></SweetAlert>
    );

    const user_id = this.refs.user.value;
    const fromAddress = this.refs.fromAddress.value;
    const toAddress = this.refs.toAddress.value;
    const transactionHash = this.refs.transactionHash.value;
    const status = this.state.status;
    const amount = Number(this.refs.amount.value);
    const comments = this.refs.comments.value;
    const currency = this.state.selectedCurrency;
    const round = this.state.selectedRound;

    var postData = {
      user_id: user_id,
      fromAddress: fromAddress,
      toAddress: toAddress,
      transactionHash: transactionHash,
      status: status,
      amount: amount,
      comments: comments,
      currency: currency,
      rounds:round
    };

    api
      .post("transaction/create", postData)
      .then((res) => {
        this.setState({
          alert: getSuccessAlert(res.data.msg),
        });
      })
      .catch((err) => {
        this.setState({
          alert: getfailureAlert(err.response.data.msg),
        });
        $("input").val("");
      });
  }

  hideAlertSuccess() {
    this.props.myID
      ? browserHistory.push(`/Users/UserProfile/${this.props.myID}`)
      : browserHistory.push(`/Transactions`);
  }
  hideAlertFailure() {
    this.setState({
      alert: null,
    });
  }

  getUserDetails = () => {
    api
      .get(`user/getDetails?user_id=${this.props.myID}`)
      .then((response) => {
        const details = response.data.data;
        this.setState({ details });
      })
      .catch((err) => {
        console.log("something went wrong");
      });
  };

  ChangeStatus = (e) => {
    this.setState({ status: e.target.value }, () => {});
  };
  ChangeCurrency = (e) => {
    this.setState({ selectedCurrency: e.target.value }, () => {});
  };
  ChangeRound = (e) => {
    this.setState({ selectedRound: e.target.value }, () => {});
  };
  render() {
    let optionItems;
    let roundItems;
    if (this.state.currencies) {
      let currencies = this.state.currencies;
      optionItems = this.state.currencies ? (
        currencies.map((currency) => (
          <option key={currency} value={currency}>
            {currency}
          </option>
        ))
      ) : (
        <options />
      );
    } else {
      optionItems = [];
    }
    if (this.state.Rounds) {
      let rounds = this.state.Rounds;
      roundItems = this.state.Rounds ? (
        rounds.map((round) => (
          <option key={round} value={round}>
            {round}
          </option>
        ))
      ) : (
        <options />
      );
    } else {
      roundItems = [];
    }
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">{this.state.error}</p>
      ) : (
        <span />
      );
    return (
      <div className="create-transaction-wrapper">
        <div className="clearfix"></div>
        <div className="Transaction">
          <h2>Create Transaction</h2>
          <div className="field-block">
            <div className="field-wrapper">
              <label htmlFor="user">User * :</label>
              <input
                type="text"
                ref="user"
                defaultValue={this.props.myID ? this.props.myID : ""}
                disabled={this.props.myID ? true : false}
                id="user"
                className="form-control"
                name="user"
              />
            </div>
            <div className="field-wrapper">
              <label htmlFor="fromAddress ">From Address * :</label>
              <input
                type="text"
                ref="fromAddress"
                defaultValue={this.state.details && this.state.details?.walletId}
                disabled={this.state.details?.walletId? true : false}
                id="fromAddress  "
                className="form-control"
                name="fromAddress"
              />
            </div>
          </div>
          <div className="field-block">
            <div className="field-wrapper">
              <label htmlFor="toAddress ">To Address * :</label>
              <input
                type="text"
                ref="toAddress"
                defaultValue={config.WALLET_ADDRESS}
                disabled
                id="toAddress "
                className="form-control"
                name="toAddress"
              />
            </div>
            <div className="field-wrapper">
              <label htmlFor="Amount">Transaction Hash * :</label>
              <input
                type="text"
                ref="transactionHash"
                id="transactionHash "
                className="form-control"
                name="transactionHash"
              />
            </div>
          </div>
          <div className="field-block">
            <div className="field-wrapper">
              <label>Select Type:</label>
              <select
                className="form-control"
                onChange={this.ChangeStatus.bind(this)}
              >
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
                <option value="reversal">Reversal</option>
              </select>
            </div>
            <div className="field-wrapper">
              <label htmlFor="amount ">Amount * :</label>
              <input
                type="number"
                ref="amount"
                id="amount"
                onKeyUp={this.floatingPoint.bind(this)}
                className="form-control"
                name="amount"
              />
            </div>
          </div>
          <div className="field-block">
            <div className="field-wrapper">
              <label htmlFor="currency">Currency * :</label>
              <select
                className="form-control"
                onChange={this.ChangeCurrency.bind(this)}
              >
                {this.state.currencies ? optionItems : <options />}
              </select>
            </div>
            <div className="field-wrapper">
              <label htmlFor="currency">ROUND*:</label>
              <select
                className="form-control"
                onChange={this.ChangeRound.bind(this)}
              >
                {this.state.Rounds ? roundItems : <options />}
              </select>
            </div>
          </div>
          <div className="field-block1">
            <label htmlFor="comments">Comments * :</label>
            <textarea
              ref="comments"
              id="comments "
              className="form-control"
              name="comments"
            ></textarea>
          </div>
          {this.state.alert}
          {errorMgs}
          <div className="form-button">
            <button
              type="submit"
              className="btn-create"
              onClick={this.doAddWithdraw.bind(this)}
            >
              Create Withdraw
            </button>
            <Link to="/Transactions">
              <div className="Transaction-cancel">
                Cancel
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default Withdraws;
