import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";
import Loader from "../Components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import "./index.scss";
import $ from "jquery";
import { Images } from "../assets";
import Web3provider from '../Helpers/Web3Provider';
import TierAllocationWalletEdit from "./tier_allocationwalletEdit";
import { browserHistory } from "react-router";
var bigInt = require("big-integer");
export default class TierAllocationWallet extends Component
{
    constructor(props)
    {
        super(props);
        this.updateState    = this.updateState.bind(this);
        this.hideAlertSuccess= this.hideAlertSuccess.bind(this);
        this.hideAlertFailure = this.hideAlertFailure.bind(this);
        this.hideDeleteAlert = this.hideDeleteAlert.bind(this);
        this.state =  {
            alert: "",
            error: "",
            tier_category_name:"",
            deleteConfirm:false,
            addtoUserWallet:false,
            tier_id: props.params.tierId,
            wallet_address:"",
            max_wallet_token:0,
            max_token_tier:0,
            balance_token_tier:0,
            available_token_tier:0,
            amount:0,
            recId:null,
            editRecord:false,
            allocations:[],
            showLoader:false
        };
        this.Web3Handler = new Web3provider();
        this.TierAllocationWalletEdit = this.TierAllocationWalletEdit.bind(this);
    }
    TierAllocationWalletEdit(recId)
    {
        const id = recId;
        this.setState({...this.state,recId:id, editRecord:true});
    }
    checkWeb3()
    {
        
    }
    componentDidMount() {
        this.getRoundData();
        this.getallocations();
        this.getRoundBalance();
        this.setState({ error: "" });
    }
    refresh = () => {
        $("input").val("");
        this.getallocations();
        this.getRoundData();
        this.getRoundBalance();
        this.setState({ error: "" });
    };

    getRoundData = () => {
        api.get(`admin/getRoundDetails/?tier_id=${this.props.params.tierId}`).then((response) => {
            console.log("Total DB"+response.data.data.max_per_wallet);
            this.setState({
                // max_wallet_token: parseInt(response.data.data.max_per_wallet)*Math.pow(10, 18),
                // max_token_tier: bigInt((response.data.data.max_per_round) * Math.pow(10,18))
                max_wallet_token: response.data.data.max_per_wallet,
                max_token_tier: response.data.data.max_per_round
            });
            api.get(`tier/gettierDetailsBySlug?tier_slug=${response.data.data.rounds}`).then((response) => {
                const details = response.data.data;
                this.setState({ tier_category_name: details.name });
            });
        }).catch((err) => {
            console.log("something went wrong with get-distribution Api :- ", err);
        });
    };

    getRoundBalance = () => {
        console.log("Called Balance ")
        api.get(`admin/getRoundBalance/?tier_id=${this.props.params.tierId}`).then((response) => {
            if(response.data.data[0].totalSum !== undefined){
                this.setState({
                    balance_token_tier: response.data.data[0].totalSum
                });
            }else{
                this.setState({
                    balance_token_tier: 0
                });
            }
            
        }).catch((err) => {
            console.log("something went wrong with get-distribution Api :- ", err);
        });
    };

    getallocations = () => {
        api.get(`admin/getallocations/?tier_id=${this.props.params.tierId}`).then((response) => {
            this.setState({
                allocations: response.data.data,
            });
        }).catch((err) => {
            console.log("something went wrong with get-distribution Api :- ", err);
        });
    };

    updateState(event)
    {
        this.setState({...this.state});
    }

    showDeleteAlert = () => {
        this.setState({
            deleteConfirm: true,
        })
    };

    
    async saveUserWallet()
    {
        console.log("Clicked HEre");
        const getfailureAlert = (msg) => (
            <SweetAlert danger title="Error" onConfirm={this.hideAlertFailure}>
                {msg}
            </SweetAlert>
        );

        const getSuccessAlert = (msg) => (
            <SweetAlert success title="Success!" onConfirm={this.hideAlertSuccess}>
                {msg}
            </SweetAlert>
        );
        console.log("Total "+this.state.max_wallet_token);
        let user_name = this.refs.name.value;
        let wallet_address = this.refs.wallet_address.value;
        let amount = this.refs.amount.value;
        let tier_balance = parseInt(this.refs.max_amount.value);
        let control = this.state;
        console.log("Amount" + amount);
        console.log("Balance" + tier_balance);
        if (control.tier_id === "") {
            this.setState({
                error: "Tier ID is required field",
            });
        } else if (wallet_address === "") {
            this.setState({
                error: "Wallet Address is required field",
            });
        } else if (user_name === "") {
            this.setState({
                error: "User Name is required field",
            });
        } else if (amount === "") {
            this.setState({
                error: "No of Token is required field",
            });
        }else if (amount > tier_balance) {
            this.setState({
                error: "Token Amount is greater that Available Token",
            });
        }else{
            let postData = {
                allocatedTo:wallet_address,
                tierId:control.tier_id || this.props.params.tierId,
                numTokens: amount
            };
            let postDataDB = {
                name:user_name,
                allocatedTo:wallet_address,
                tierId:control.tier_id || this.props.params.tierId,
                numTokens: amount
            };
            api.showLoader();
            this.setState({...this.state,showLoader:true});
        
            console.log("Provider");

            this.Web3Handler.allocateUserWalletTokens(postData).then(res=>{
                          
                api.post("admin/wallet_allocated",postDataDB).then((res) => {
                    api.hideLoader();
                    this.setState({
                        alert: getSuccessAlert(res.data.msg),
                    });
                }).catch((err) => {
                    api.hideLoader();
                    this.setState({
                        alert: getfailureAlert("Something went wrong."),
                    });
                });
            }).catch(err=>{
                api.hideLoader();
                this.setState({
                    alert: getfailureAlert("Something went wrong."),
                });
            });
        }
    }

    hideAlertSuccess() {
        api.hideLoader();
        this.setState({
            alert: null,
            showLoader:false,
        });
        this.refresh();
    }
    
    hideAlertFailure() {
        this.setState({
            alert: null,
            showLoader:false
        });
    }

    hideDeleteAlert() {
        this.setState({
            deleteConfirm: "",
            showLoader:false
        });
    }
    onTierAllocationOrVestingSuccess()
    {
        this.setState({...this.state,tier:{}, editRecord:false});
    }
    gotoRound() {
        browserHistory.push(`/Rounds`);
    }

    render()
    {
        if(this.state.editRecord)
            return <TierAllocationWalletEdit tier_id={this.state.tier_id} recId={this.state.recId} onSuccess={this.onTierAllocationOrVestingSuccess}/>
        let allocations = this.state.allocations;
        let MaxTokenTierString = this.state.max_token_tier.toString();
        // let MaxTokenTier = Number((this.state.max_token_tier.toString()).slice(0, 4))+"...."+MaxTokenTierString.substring(MaxTokenTierString.length - 4);
        let MaxTokenTier = Number(this.state.max_token_tier.toString());
        let BalanceToken = MaxTokenTier;
        if(this.state.balance_token_tier == 0){
            BalanceToken = this.state.max_token_tier.toString();
        }else{
            BalanceToken = this.state.max_token_tier - this.state.balance_token_tier;
        }

        let errorMgs =
        this.state.error !== "" ? (
            <p className="alert warning">
                <i className="fa fa-info-circle"></i> {this.state.error}
            </p>
        ) : (
            <span />
        );

        return (
            <div className="Round-page">
                <Loader />
                <Header />
                <div className="wrapper">
                    <div className="Round">
                        <h2>Allocate Token For: Tier `{ this.props.params.tierId }` , Tier Label  `{ this.state.tier_category_name }`</h2>
                        <h4>Max Tokens For This Tier`{ MaxTokenTier }`</h4>
                        <h4>Remaining Tokens To Allocate `{ BalanceToken }`</h4>
                        {this.state.alert}
                        <>
                            <div className="Round-form">
                                <div className="field-block">
                                    <div className="field-wrapper">
                                        <label>User Name</label>
                                        <input className="form-elements" ref="name" name="name" type="text" />
                                    </div>
                                    <div className="field-wrapper">
                                        <label>Wallet Address</label>
                                        <input className="form-elements" ref="wallet_address" name="wallet_address" type="text" />
                                    </div>
                                    <div className="field-wrapper">&nbsp;</div>
                                </div>
                                <div className="field-block">
                                    <div className="field-wrapper">
                                        <label>Token Assigned</label>
                                        <input className="form-elements" ref="amount" name="amount"  type="number" />
                                        <input className="form-elements" value={BalanceToken} ref="max_amount" name="max_amount"  type="hidden" />
                                    </div>
                                    <div className="field-wrapper">&nbsp;</div>
                                </div>
                                <br />
                                {errorMgs}
                                <div className="form-button">
                                    <button className="btn-save" id="_allocate_wallet" onClick={this.saveUserWallet.bind(this)} name="allocate_wallet" disabled={this.state.showLoader} > Save </button>
                                    <button className="btn-cancel" id="_allocate_wallet_cancel" onClick={this.hideAlertFailure} name="allocate_wallet_cancel" disabled={this.state.showLoader} > Cancel </button> 
                                    <button className="btn-cancel" id="_allocate_wallet_back" onClick={this.gotoRound} name="allocate_wallet_back" disabled={this.state.showLoader} > Back </button>
                                </div>
                            </div>
                            <br />
                        </>   

                        <div className="round-table-wrapper">
                            <h2>Alloted Wallet Details</h2>
                            <table className="round-table">
                                <thead>
                                    <tr>
                                        <th>User Name</th>
                                        <th>Wallet Address</th>
                                        <th>Token Alloted</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allocations.length > 0 ? (
                                        allocations.map((wallet, i) => (
                                            <tr>
                                                <td data-label="username">
                                                    <span className="Month uppercase">
                                                        {wallet.name}
                                                    </span>
                                                </td>
                                                <td data-label="Date">
                                                    <span className="Month uppercase">
                                                        {wallet.allocatedTo}
                                                    </span>
                                                </td>
                                                <td data-label="Date">
                                                    <span className="Month uppercase">
                                                        {wallet.numTokens}
                                                    </span>
                                                </td>
                                                <td className="action-btns">
                                                    <img src={Images.DeductIcon} alt={""} alt={"Deduct Token from Wallet"} title={"Deduct Token from Wallet"} onClick={() => this.TierAllocationWalletEdit(wallet._id)} />  
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" className="align-center">
                                                No Wallet Distibutions Available
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}