import React,{useState} from "react";
import { Link } from "react-router";
import "./index.scss";
import Swal from 'sweetalert2';
import api from "../../utils/api";

function RoundSubHeader() {


  return (
    <div className="home-header">
      <div className="home-header-wrapper">
        <Link to={"/Rounds/WhiteListUsers"} className="header-item" activeClassName="activeRoute">
          White List Users
        </Link>
        
      </div>
    </div>
  );
}
export default RoundSubHeader;
