export const Images = {
    Logo:require("./resources/adminLogo.png"),
    circular_loader: require("./resources/circular-loader.svg"),
    ellipsis_loader: require("./resources/ellipsis-loader.svg"),
    BNB:require('./resources/Image 10@2x.png'),
    BUSD:require('./resources/Image 11@2x.png'),
    ADD_ICON:require('./resources/Icon-Add.svg'),
    VERIFIED:require('./resources/verified.svg'),
    UNAPPROVED:require('./resources/unconfirmed.svg'),
    EditIcon:require('./resources/Edit Icon.svg'),
    whitelist:require('./resources/whitelist.svg'),
    Tierdistribution:require('./resources/distribution.svg'),
    TierWalletdistribution:require('./resources/walleticon.png'),
    Tiervesting:require('./resources/vesting-icon.svg'),
    DeleteIcon:require('./resources/icon-delete.svg'),
    Timer:require('./resources/timer.svg'),
    DeductIcon:require('./resources/deduct_icon.svg'),
    
}