import React, { Component } from "react";
import Header from "../Components/Header";
import api from "../utils/api.js";
import Loader from "../Components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import "./index.scss";
import Web3provider from '../Helpers/Web3Provider';
export default class TierAllocationWalletEdit extends Component
{
    constructor(props)
    {
        super(props);
        this.updateState    = this.updateState.bind(this);
        this.hideAlertSuccess= this.hideAlertSuccess.bind(this);
        this.hideAlertFailure = this.hideAlertFailure.bind(this);
        this.state =  {
            alert: "",
            error: "",
            tier_category_name:"",
            deleteConfirm:false,
            addtoUserWallet:false,
            tier_id:props.tier_id,
            wallet_address:"",
            walletRecord:[],
            amount:0,
            maxAmount: 0,
            showLoader:false
        };
        this.Web3Handler = new Web3provider();
        
    }
    componentDidMount() {
        this.getwalletRecord();
    }
    refresh = () => {
        window.location.reload(false);
    };
    getwalletRecord = () => {
        api.get(`admin/getwalletRecord/?recId=${this.props.recId}`).then((response) => {
            this.setState({
                walletRecord: response.data.data,
                maxAmount: response.data.data.numTokens,
            });
            api.get(`admin/getRoundDetails/?tier_id=${response.data.data.tierId}`).then((response) => {
                api.get(`tier/gettierDetailsBySlug?tier_slug=${response.data.data.rounds}`).then((response) => {
                    const details = response.data.data;
                    this.setState({ tier_category_name: details.name });
                });
            });
        }).catch((err) => {
            console.log("something went wrong with get-distribution Api :- ", err);
        });
    };

    updateState(event)
    {
        this.setState({...this.state});
    }

    async UpdateUserWallet()
    {
        console.log("Clicked HEre");
        const getfailureAlert = (msg) => (
            <SweetAlert danger title="Error" onConfirm={this.hideAlertFailure}>
                {msg}
            </SweetAlert>
        );

        const getSuccessAlert = (msg) => (
            <SweetAlert success title="Success!" onConfirm={this.hideAlertSuccess}>
                {msg}
            </SweetAlert>
        );
        let user_name = this.refs.name.value;
        let wallet_address = this.refs.wallet_address.value;
        let amount = this.refs.amount.value;
        let recID = this.refs.recId.value;
        let control = this.state;
        let checkDelete = false;
        if (amount == this.state.maxAmount) {
            checkDelete = true;
        }
        if (control.tier_id === "") {
            this.setState({
                error: "Tier ID is required field",
            });
        } else if (wallet_address === "") {
            this.setState({
                error: "Wallet Address is required field",
            });
        } else if (user_name === "") {
            this.setState({
                error: "User Name is required field",
            });
        } else if (amount === "") {
            this.setState({
                error: "No of Token is required field",
            });
        } else if (amount > this.state.maxAmount) {
            this.setState({
                error: "Token Amount is greater that Available Token",
            });
        }else{
            let db_tokens = parseInt(this.state.maxAmount)-parseInt(amount);
            let postData = 
            {
                id:recID || this.props.recID,
                allocatedTo:wallet_address || this.props.wallet_address,
                tierId:control.tier_id || this.props.tier_id,
                numTokens:db_tokens,
                allocatedTo:wallet_address || this.props.wallet_address,
                name:user_name,
            };
            let apiPostData = 
            {
                allocatedTo:wallet_address || this.props.wallet_address,
                tierId:control.tier_id || this.props.tier_id,
                numTokens:amount || this.props.amount 
            };
            api.showLoader();
            this.setState({...this.state,showLoader:true});

            console.log("Provider");

            this.Web3Handler.removeUserWalletTokens(apiPostData).then(res=>{

                if(checkDelete){
                    api.post("admin/wallet_allocated_delete",postData).then((res) => {
                        api.hideLoader();
                        this.setState({
                            alert: getSuccessAlert(res.data.message),
                        });
                        console.log("Edit Success");
                    }).catch((err) => {
                        api.hideLoader();
                        this.setState({
                            alert: getfailureAlert("Something went wrong"),
                        });
                        console.log("Edit Error");
                    });
                }else{
                    api.post("admin/wallet_allocated_remove",postData).then((res) => {
                        api.hideLoader();
                        this.setState({
                            alert: getSuccessAlert(res.data.message),
                        });
                        console.log("Edit Success");
                    }).catch((err) => {
                        api.hideLoader();
                        this.setState({
                            alert: getfailureAlert("Something went wrong"),
                        });
                        console.log("Edit Error");
                    });
                }
                          
                
            }).catch(err=>{
                api.hideLoader();
                this.setState({
                    alert: getfailureAlert("Something went wrong"),
                });
                console.log("Edit Error 2");
            });
        }
    } 

    hideAlertSuccess() {
        api.hideLoader();
        this.setState({
            alert: null,
            showLoader:false,
        });
        this.refresh();
    }
    
    hideAlertFailure() {
        this.setState({
            alert: null,
            showLoader:false
        });
        this.refresh();
    }


    render()
    {
        let walletRecord = this.state.walletRecord;
        let errorMgs =
        this.state.error !== "" ? (
            <p className="alert warning">
                <i className="fa fa-info-circle"></i> {this.state.error}
            </p>
        ) : (
            <span />
        );

        return (
            <div className="Round-page">
                <Loader />
                <Header />
                <div className="wrapper">
                    <div className="Round">
                        <h2>Deduct Token For: Tier `{ this.props.tier_id }` , Tier Label  `{ this.state.tier_category_name }`</h2>
                        {this.state.alert}
                        {walletRecord !== null ? (
                        <>
                            <div className="Round-form">
                                <div className="field-block">
                                    <div className="field-wrapper">
                                        <label>User Name</label>
                                        <input className="form-elements" defaultValue= { walletRecord.name } ref="name" name="name" type="text" />
                                    </div>
                                    <div className="field-wrapper">
                                        <label>Wallet Address</label>
                                        <input type="hidden" ref="recId" id="recId"  defaultValue = { walletRecord._id } name="recId" />
                                        <input className="form-elements" defaultValue= { walletRecord.allocatedTo } ref="wallet_address" name="wallet_address" ref="wallet_address"  type="text" />
                                    </div>
                                    <div className="field-wrapper">&nbsp;</div>
                                </div>
                                <div className="field-block">
                                    <div className="field-wrapper">
                                        <label>Token Assigned (Alloted Tokens : { walletRecord.numTokens })</label>
                                        <input className="form-elements" min="0" max = { walletRecord.numTokens }  placeholder="How many tokens do you want to deduct?" ref="amount" name="amount" ref="amount" type="number" />
                                    </div>
                                    <div className="field-wrapper">&nbsp;</div>
                                </div>
                                <br />
                                {errorMgs}
                                <div className="form-button">
                                    <button className="btn-save" id="_allocate_wallet" onClick={this.UpdateUserWallet.bind(this)} name="allocate_wallet" disabled={this.state.showLoader} > Update </button>
                                    <button className="btn-cancel" id="_allocate_wallet_back" onClick={this.refresh} name="allocate_wallet_back" disabled={this.state.showLoader} > Back </button>
                                </div>
                            </div>

                            </>
                            ) : (
                            <div />
                        )} 
                    </div>
                </div>
            </div>
        );
    }
}