import React, { Component } from 'react';
import Header from '../Components/Header';
import Loader from '../Components/Loader';

class AddWithdraw extends Component {
  render() {
    return (
      <div className="main_container">
        <Header />
        <Withdraw />
      </div>
    );
  }
}

class Withdraw extends Component {
  render() {
    return (

      <div className="right_col" role="main">
        <Loader />
        <div className="">
          <div className="page-title">
            <div className="title_left">
              Create Withdraw
            </div>

            <div className="title_right">
              <div className="col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search">
                <div className="input-group">

                </div>
              </div>
            </div>
          </div>

          <div className="clearfix"></div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">


              <button type="button" className="btn btn-success" data-ico-target="#icoDemo" data-type="ico-modal">Open Modal</button>

              <div id="icoDemo" className="popup">
                <div className="ico-dialog">
                  <div className="ico-content">
                    <div className="ico-header">
                      <button type="button" className="close" data-dismiss="ico-modal">&times;</button>
                      <h4 className="ico-title">Update Password</h4>
                    </div>

                    <div className="ico-body">

                    </div>

                    <div className="ico-footer">
                      <button type="button" className="btn btn-success">Update</button>
                    </div>


                  </div>

                </div>
              </div>



            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddWithdraw;
