import React, { Component } from "react";
import Header from "../..//Components/Header";
import api from "../../utils/api.js";
import Loader from "../../Components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import { browserHistory } from "react-router";
import "../index.scss";
import moment from "moment";
import { Images } from "../../assets";
import RoundSubHeader from "../../Components/Round-subheader";
import Web3provider from '../../Helpers/Web3Provider';
import TierAllocation from '.././tier_allocation';

let user;
class WhiteListUser extends Component {
  constructor(props) {
    user = JSON.parse(localStorage.getItem("user_profile"));
    super(props);
    this.onSucess       = props.onSuccess; 
    this.state = {
      alert: "",
      error: "",
      RoundDetails: [],
      selectedCategory: "",
      showTable: true,
      update: "",
      tier_id:props.tier_id,
      tier:{},
      tier_allocation:false,
      showLoader:false,
    };
    this.web3Client = new Web3provider();
    this.hideAlertSuccess = this.hideAlertSuccess.bind(this);
    this.hideAlertFailure = this.hideAlertFailure.bind(this);

  }
  componentWillMount()
  {
    this.getWhitelist(this.props.tier_id);
  }
  getfailureAlert = (msg) => (
    <SweetAlert danger title="Error" onConfirm={this.hideAlertFailure}>
      {msg}
    </SweetAlert>
  );
  getSuccessAlert = (msg) => (
    <SweetAlert success title="Success!" onConfirm={this.hideAlertSuccess}>
      {msg}
    </SweetAlert>
  );
  getWhitelist(tier_id)
  {
    api.get(`admin/whitelist-address/${tier_id}`)
            .then((res) => {
              this.setState({...this.state,showLoader:false});
              this.setState({
                whitelist:res.data.data,
                //alert: getSuccessAlert(res.data.msg),
              });
            })

  }
  DeleteAddress(tier_id,address)
  {
    api.showLoader();
    this.setState({...this.state,showLoader:true});
    this.web3Client.removeWhitelistUserAddress({tier_id,address})
    .then(res=>{
    api.post(`admin/remove-whitelist-address`,{tier_id,address})
            .then((res) => {
              api.hideLoader();
              this.setState({
                //whitelist:res.data.data,
                showLoader:false,
                alert: this.getSuccessAlert(res.data.msg),
              });
            })
          })
          .catch(err=>{
            api.hideLoader();
              this.setState({
                //whitelist:res.data.data,
                showLoader:false,
                alert: this.getfailureAlert(err.message || err),
              });
          })
  }
  whiteListAddress = async (event)=>{




    if(this.fieldValidated()===true) {
      this.setState({
        error: "",
      });
      const getfailureAlert = (msg) => (
        <SweetAlert danger title="Error" onConfirm={this.hideAlertFailure}>
          {msg}
        </SweetAlert>
      );
      const getSuccessAlert = (msg) => (
        <SweetAlert success title="Success!" onConfirm={this.hideAlertSuccess}>
          {msg}
        </SweetAlert>
      );
      const whitelistuser_address = this.refs.whitelistuser.value;
      const tier_id               = this.refs.tier_id.value;
      api.showLoader();
      this.setState({...this.state,showLoader:true});
      this.web3Client.whitelistUserAddress({tier_id,whitelistuser_address})
          .then(res=>{
            api
            .post(`admin/whitelist-address`, {tier_id,address:whitelistuser_address})
            .then((res) => {
              api.hideLoader();
               this.setState({
                update:{},
                showLoader:false,
                alert: getSuccessAlert(res.data.msg),
              });
            })
          })
          .catch((err) => {
            api.hideLoader();
            this.setState({
              showLoader:false,
              alert: getfailureAlert(err.message ||  err),
            });
          });
    //  if(!status)
    //  {

    //    this.setState({alert:getfailureAlert("Wallet transaction failed")})
    //  }
    //  let sTransactionHash = blockHash; 
    //  return this.setState({alert:getSuccessAlert("User Address Whitelisted Successfully")}); 
      // var postData = {
      //   round: round,
      //   start_date: start_date,
      //   end_date: end_date,
      //   max_per_wallet,
      //   max_per_round,
      //   price,
      //   round_type,
      //   sTransactionHash
      // };

  }
}
  fieldValidated()
  {
    // if (this.refs.whitelistuser.length<=0) {
    //   this.setState({
    //     error: "Please Select File",
    //   });
    // } else if (this.refs.startDate.value === "") {
    //   this.setState({
    //     error: "Start Date is required field",
    //   });
    // }
    if (this.refs.whitelistuser==="") {
      this.setState({
        error: "White List User is required field",
      });
    } else if (this.refs.tier_id.value === "") {
      this.setState({
        error: "Tier ID is required field",
      });
    }
    else return true;
  }


  hideAlertSuccess() {
    this.setState({
      alert: null,
    });
    // this.getRounds();
    this.refs.whitelistuser.value = "";
    this.refs.tier_id.value = "";
    this.onSucess();

  }

  hideAlertFailure() {
    this.setState({
      alert: null,
    });
  }
  componentDidMount() {
    this.getRounds();
  }
  getRounds = () => {
    api
      .get(`admin/getrounds`)
      .then((response) => {
        const Round = response.data.data;
        this.setState({
          RoundDetails: Round,
        });
      })
      .catch((err) => {
        console.log("something went wrong with Round Api :- ", err);
      });
  };
  



  
  render() {

    let optionItems;
    let whitelist = this.state.whitelist;
    let errorMgs =
      this.state.error !== "" ? (
        <p className="alert warning">
          <i className="fa fa-info-circle"></i> {this.state.error}
        </p>
      ) : (
        <span />
      );
    return (
      <div className="Round-page">
        <Loader />
        <Header />
        
        <div className="wrapper">
        <div className="Round">
            <h2>Whitelist for `{this.state.tier_id}`</h2>
            {this.state.alert}
            {/* <p className="message">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p> */}
            {this.state.showTable ? (
              <>
                <div className="Round-form">
                  <div className="field-block">
                    <div className="field-wrapper">
                      <label>White List Address</label>
                       {/*<textarea
                        className="form-elements"
                        ref="whitelistuser"
                        type="text"
                        style={{width:"600px",height:"100px"}}
                      ></textarea>
                      <em>Multiple values with comman(,) seperated</em>*/}
                     <input
                        className="form-elements"
                        ref="whitelistuser"
                        type="text"
                        
                      />
                      <input
                        className="form-elements"
                        ref="tier_id"
                        type="hidden"
                        defaultValue={this.props.tier_id}
                      />
                    </div>
                    <div className="field-wrapper">
                    &nbsp;
                      
                    </div>
                    <div className="field-wrapper">&nbsp;</div>
                   
                  
                  </div>
                 <br />
                  
                  {errorMgs}
                  <div className="form-button">
                    <button
                      className="btn-save"
                      id="_profile_update"
                      onClick={this.whiteListAddress.bind(this)}
                      name="profileupdate"
                    >
                      Save
                    </button>
                    <button
                      className="btn-cancel"
                      id="_profile_update"
                       onClick={this.onSucess}
                      name="profileupdate"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <br />
                <div className="round-table-wrapper">
                  <h2>Whitelist Address</h2>
                  <table className="round-table">
                    <thead>
                      <tr>
                        <th className="align-left">Address</th>
                        
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {whitelist && whitelist.length > 0 ? (
                        whitelist.map((address, i) => (
                          <tr>
                            <td className="align-left">{address}</td>
                            
                            <td className="action-btns">
                             
                              <img
                                src={Images.DeleteIcon}
                                alt={"Delete Address"}
                                onClick={() => this.DeleteAddress(this.props.tier_id,address)}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="align-center">
                            No Address Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              
                {/*<div className="round-table-wrapper">
                  <h2>Round Details</h2>
                  <table className="round-table">
                    <thead>
                      <tr>
                        <th className="align-left">ROUND</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {RoundDetails && RoundDetails.length > 0 ? (
                        RoundDetails.map((list, i) => (
                          <tr>
                            <td className="align-left">{list.rounds}</td>
                            <td data-label="Date">
                              <span className="Month uppercase">
                                {moment(list.start_date).format("DD MMM, YYYY")}
                              </span>
                            </td>
                            <td data-label="Date">
                              <span className="Month uppercase">
                                {moment(list.end_date).format("DD MMM, YYYY")}
                              </span>
                            </td>
                            <td className="action-btns">
                            <img
                                src={Images.EditIcon}
                                alt={""}
                                onClick={() => this.TierAllocation(list)}
                              />
                              &nbsp; &nbsp;
                              <img
                                src={Images.EditIcon}
                                alt={""}
                                onClick={() => this.EditRound(list)}
                              />
                              &nbsp; &nbsp;
                              <img
                                src={Images.DeleteIcon}
                                alt={""}
                                onClick={() => this.DeleteRound(list._id)}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="align-center">
                            No Rounds Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                      </div>*/}
              </>
            ) : (
              <div className="Round-form">
                <div className="field-block">
                  <div className="field-wrapper">
                    <label>Category</label>
                    <select
                      className="form-elements category"
                      id="category"
                      ref="category"
                      name="category"
                    >
                      <option value="-1" disabled>
                        Select Category
                      </option>
                      {/* <option
                        value="pre-seed"
                        selected={this.state.update.rounds === "pre-seed"}
                      >
                        Pre-Seed
                      </option>
                      <option
                        value="seed"
                        selected={this.state.update.rounds === "seed"}
                      >
                        Seed
                      </option> */}
                      <option
                        value="pre-sale-whitelist"
                        selected={
                          this.state.update.rounds === "pre-sale-whitelist"
                        }
                      >
                        Pre-sale Whitelist
                      </option>
                      <option
                        value="pre-sale-public"
                        selected={
                          this.state.update.rounds === "pre-sale-public"
                        }
                      >
                        Pre-sale Public
                      </option>
                    </select>
                  </div>
                  <div className="field-wrapper">
                    <label>START DATE</label>
                    <input
                      className="form-elements"
                      ref="startDate"
                      type="date"
                      defaultValue={moment(this.state.update.start_date).format(
                        "YYYY-MM-DD"
                      )}
                    />
                  </div>
                  <div className="field-wrapper">
                    <label>END DATE</label>
                    <input
                      className="form-elements"
                      ref="endDate"
                      type="date"
                      defaultValue={moment(this.state.update.end_date).format(
                        "YYYY-MM-DD"
                      )}
                    />
                  </div>
                </div>
                <div className="field-block">
                    <div className="field-wrapper">
                      <label>Max Tokan Per Wallet</label>
                      <input
                        className="form-elements"
                        ref="max_per_wallet"
                        type="number"
                        defaultValue={this.state.max_per_wallet}
                      />
                    </div>
                    <div className="field-wrapper">
                      <label>Max Tokan Per Tier</label>
                      <input
                        className="form-elements"
                        ref="max_per_round"
                        type="number"
                        defaultValue={this.state.max_per_round}
                      />
                    </div>
                    <div className="field-wrapper">
                      <label>price</label>
                      <input
                        className="form-elements"
                        ref="price"
                        type="number"
                        defaultValue={this.state.price}
                      />
                    </div>
                    </div>
                    <div className="field-block">
                    <div className="field-wrapper">
                      <label>Is Private</label>
                      
                      <input
                        className="form-elements"
                        ref="is_private"
                        type="radio"
                        checked={this.state.round_type}
                      /><label>Yes</label>
                      <input
                        className="form-elements"
                        type="radio"
                        checked={!this.state.round_type}
                      /><label>No</label>
                    </div>
                    </div>
                  
                <br />
                {errorMgs}
                <div className="form-button">
                  <button
                    className="btn-save"
                    id="_profile_update"
                    onClick={this.CreateUpdateRound.bind(this)}
                    name="profileupdate"
                  >
                    Update
                  </button>
                  <button
                    className="btn-cancel"
                    id="_profile_update"
                    onClick={this.onSuccess}
                    name="profileupdate"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        
       </div>
      </div>
    );
  }
}

export default WhiteListUser;
